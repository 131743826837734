// Local storage
export const LOCAL_STORAGE_KEY = {
  LANG: "Language",
  TIME_ZONE: "TimeZone"
} as const;

export const SESSION_STORAGE_KEY = {
  ACTIVE_DASHBOARD_ID: "ActiveDashboardID",
  SAVE_DEVICE_LOGIN_INFO: "SaveDeviceLoginInfo",
  ORG_ID: "org_id",
  TIME_ZONE: "TimeZone"
} as const;

export const THEME_TYPE = {
  DARK: "Dark",
  LIGHT: "Light"
} as const;

export const COLOR_MODE = { ...THEME_TYPE, SYSTEM: "System" as const };

export const LANG = {
  EN: "En",
  KO: "Ko"
} as const;

// Max length
export const MAX_LENGTH = {
  USER_ID: 30,
  USER_NAME: 31,
  PHONE_NUMBER: 34,
  MEMO: 1000
} as const;

// Cookie
export const USER_INFO = "UserInfo";
export const ACCESS_TOKEN = "AccessToken";
export const REFRESH_TOKEN = "RefreshToken";

// Grid
export const GRID_UNIT = 5;
export const GRID_GAP = 20;
export const WIDTH_PER_GRID_UNIT = 284;
export const GRID_BREAKPOINT_TYPE = {
  XS: "xs",
  SM: "sm",
  MD: "md",
  LG: "lg",
  XL: "xl",
  XXL: "xxl"
} as const;

// Dashboard
export const MAX_DASHBOARD_COUNT = 8;
export const MIN_DASHBOARD_SEQUENCE_CONVERSION_TIME = 30;
export const MAX_DASHBOARD_SEQUENCE_CONVERSION_TIME = 120;
export const DEFAULT_DASHBOARD_SEQUENCE_CONVERSION_TIME = 60;

// Widget
export const MAX_LENGTH_WIDGET_NAME = 50;
export const MIN_LENGTH_DASHBOARD_NAME = 1;
export const MAX_LENGTH_DASHBOARD_NAME = 32;
export const MAX_WIDGET_COUNT_ON_DASHBOARD = 10;
export const CHART_TOOLTIP_MARKER_SIZE = "6px";
export const ECHART_VEHICLE_STATISTICS_SIZE = 5;
export const DEFAULT_WIDGET_MAIN_PROPORTION = 50;

export const WIDGET_TYPE = {
  CUMULATE_PEOPLECOUNTS: "CUMULATE_PEOPLECOUNTS",
  CROWD_SAFETY_STATISTICS: "CROWD_SAFETY_STATISTICS",
  STAYING_PEOPLECOUNTS: "STAYING_PEOPLECOUNTS",
  ENTRY_EXIT_PEOPLECOUNTS: "ENTRY_EXIT_PEOPLECOUNTS",
  RANK_PEOPLECOUNTS: "RANK_PEOPLECOUNTS",
  NUMBER_OF_PEOPLE_BY_AREA: "NUMBER_OF_PEOPLE_BY_AREA",
  CHECKOUT_QUEUE: "CHECKOUT_QUEUE",
  SLIP_AND_FALL: "SLIP_AND_FALL",
  MES_MANAGEMENT: "MES_MANAGEMENT",
  TEMPERATURE_EVENT: "TEMPERATURE_EVENT",
  TEMPERATURE_STATISTICS: "TEMPERATURE_STATISTICS",
  STOPPED_VEHICLE_STATISTICS: "STOPPED_VEHICLE_STATISTICS",
  STOPPED_VEHICLE_EVENT: "STOPPED_VEHICLE_EVENT",
  TRAFFIC_JAM_EVENT: "TRAFFIC_JAM_EVENT",
  CUMULATE_VEHICLECOUNTS: "CUMULATE_VEHICLECOUNTS",
  FORKLIFT_SPEED_EVENT: "FORKLIFT_SPEED_EVENT",
  CUMULATE_SHOPPING_CART_COUNTS: "CUMULATE_SHOPPING_CART_COUNTS",
  SHOPPING_CART_CHECKOUT_QUEUE: "SHOPPING_CART_CHECKOUT_QUEUE",
  SHOPPING_CART_COUNT: "SHOPPING_CART_COUNT",
  SHOPPING_CART_QUEUE_MANAGEMENT: "SHOPPING_CART_QUEUE_MANAGEMENT",
  SHOPPING_CART_LOSS_EVENT: "SHOPPING_CART_LOSS_EVENT",
  VEHICLE_QUEUE: "VEHICLE_QUEUE",
  // Heatmap
  HEATMAP_PEOPLE: "HEATMAP",
  // Map
  RETAIL_MAP: "RETAIL_MAP",
  FACTORY_MAP: "FACTORY_MAP",
  CITY_MAP: "CITY_MAP",
  COMBINED_MAP: "COMBINED_MAP",
  SLIP_AND_FALL_STATISTICS: "SLIP_AND_FALL_STATISTICS",
  FORKLIFT_SPEED_STATISTICS: "FORKLIFT_SPEED_STATISTICS",
  SLOW_TRAFFIC_STATISTICS: "SLOW_TRAFFIC_STATISTICS"
} as const;

export const WIDGET_STYLE_TYPE = {
  MAIN: "MAIN",
  EXPANSION: "EXPANSION",
  MAP: "MAP"
} as const;

export const WIDGET_MAIN_TYPE = {
  SUMMARY: "SUMMARY",
  CHART: "CHART",
  LIST: "LIST",
  LIST_BLOCK: "LIST_BLOCK",
  MAP: "MAP"
} as const;

export const WIDGET_EXPANSION_TYPE = {
  CHART: "CHART",
  LIST: "LIST",
  THUMBNAIL: "THUMBNAIL"
} as const;

export const CHART_TYPE = {
  // BAR
  BAR: "BAR",
  BAR_LINE: "BAR_LINE",
  BAR_NEGATIVE: "BAR_NEGATIVE",
  BAR_CATEGORY: "BAR_CATEGORY",
  BAR_CATEGORY_STACK: "BAR_CATEGORY_STACK",
  BAR_HORIZONTAL: "BAR_HORIZONTAL",
  BAR_STACK: "BAR_STACK",

  // LINE
  LINE: "LINE",
  LINE_SECTION: "LINE_SECTION",
  LINE_AREA: "LINE_AREA",
  LINE_AREA_SECTION: "LINE_AREA_SECTION",
  LINE_AREA_NEGATIVE: "LINE_AREA_NEGATIVE",
  LINE_MULTILINE: "LINE_MULTILINE",

  // RADAR
  RADAR: "RADAR",

  // PIE
  PIE: "PIE",
  PIE_DOUGHNUT: "PIE_DOUGHNUT",
  PIE_DOUGHNUT_THIN: "PIE_DOUGHNUT_THIN",

  // SCATTER
  SCATTER: "SCATTER",
  SCATTER_EFFECT: "SCATTER_EFFECT",

  // HEATMAP
  HEATMAP: "HEATMAP",
  HEATMAP_CIRCLE: "HEATMAP_CIRCLE",

  // GAUGE
  GAUGE: "GAUGE",
  GAUGE_BEELINE: "GAUGE_STRAIGHT",
  GAUGE_BEELINE_SOLID: "GAUGE_STRAIGHT_SOLID",

  // SANKEY
  SANKEY: "SANKEY",

  MIXED: {
    LINE_AND_BAR: "LINE_AND_BAR",
    LINE_AND_AREA: "LINE_AND_AREA"
  }
} as const;

// Format
export const FORMAT = {
  TIME: "HH:mm:ss", //  14:25:36
  DATE: "MM-DD-YYYY", //  03-15-2024
  DATE2: "MM/DD/YYYY", // 03/15/2024
  DATE3: "MM/DD", //  03/15
  DATE4: "MM/DD/YYYY", // 03/15/2024
  DATE_MONTH: "YYYY-MMMM-DD", //  2024-April-26
  DATE_MONTH_TYPE: "MM/DD/YYYY", // 03/15/2024
  DATE_TIME_12H: "MM/DD/YYYY A hh:mm", // 03/15/2024 PM 02:30
  DATE_TIME_12H_FULL: "MM/DD/YYYY A hh:mm:ss", // 03/15/2024 PM 02:30:45
  DATE_TIME_24H: "MM/DD/YYYY HH:mm", //   03/15/2024 14:30
  DATE_TIME2_24H: "MM/DD HH:mm", //   03/15 14:30
  TIME_12H: "A hh:mm:ss", //  PM 02:30:45
  TIME_12H_1: "A hh:mm", //  PM 02:30
  TIME_12H_2: "A hh:mm", //   02:30 PM
  DATE_TIME_ISO: "YYYY-MM-DD[T]HH:mm:ssZ", //   2024-04-26T15:30:45Z
  MONTH_DATE_YEAR: "MMM/DD/yyyy", // Mar/15/2024
  MONTH_DATE_YEAR_1: "MMM DD, yyy", // Mar 15, 2024
  TIM12: "hh:mm:ss A", // 02:30:45 PM
  MONTH_DATE_YEAR_TIME: "MMM DD, yyyy hh:mm:ss A", //  Apr 25, 2024 03:30:15 PM
  MONTH_DATE_YEAR_TIME_2: "MMM DD, yyyy hh:mm A" //  Apr 25, 2024 03:30 PM
} as const;

export const MONTH = {
  JAN: "January",
  FEB: "February",
  MAR: "March",
  APR: "April",
  MAY: "May",
  JUN: "June",
  JUL: "July",
  AUG: "August",
  SEP: "September",
  OCT: "October",
  NOV: "November",
  DEC: "December"
} as const;

export const COMPARISON_TYPE = {
  INCREASE: "INCREASE",
  DECREASE: "DECREASE",
  EQUAL: "EQUAL"
} as const;

export const SERIES_Z_VALUE = {
  // original data must not be overlapped by comparison data
  ORIGINAL_DATA: 10
};
export const HEATMAP_MODE = {
  TODAY: "TODAY",
  SEARCH: "SEARCH",
  TIME_LAPSE: "TIME_LAPSE"
} as const;

export const COMPARISON_DATE_TYPE = {
  NONE: "NONE",
  YESTERDAY: "YESTERDAY",
  A_WEEK_AGO: "A_WEEK_AGO",
  A_MONTH_AGO: "A_MONTH_AGO",
  DATE: "DATE"
} as const;

export const HEATMAP_PLAY_SPEED = {
  SLOW: "SLOW",
  NORMAL: "NORMAL",
  FAST: "FAST"
} as const;

export const COUNTING_DIRECTION = {
  IN: "IN",
  OUT: "OUT"
} as const;

export const TRAFFIC_STATUS = {
  ACCIDENT: "ACCIDENT",
  SPEEDING: "SPEEDING",
  ILLEGAL: "ILLEGAL"
} as const;

export const TRAFFIC_STATUS_LABEL = {
  ACCIDENT: "Accident",
  SPEEDING: "Speeding",
  ILLEGAL: "Illegal Parking"
} as const;

export const CAMERA_STATUS_TYPE = {
  WARNING: "WARNING",
  CRITICAL: "CRITICAL",
  CLEAR: "CLEAR"
} as const;

export const TOOLTIP_PLACEMENT_TYPE = {
  TOP: "top",
  TOP_LEFT: "top-left",
  TOP_RIGHT: "top-right",
  BOTTOM: "bottom",
  BOTTOM_LEFT: "bottom-left",
  BOTTOM_RIGHT: "bottom-right"
} as const;

export const BUTTON_STYLE_TYPE = {
  FIRST: "first",
  SECOND: "second",
  THIRD: "third",
  FOURTH: "fourth",
  NORULE: "norule",
  RULE: "rule"
} as const;

export const CALENDAR_MODE_RANGE_TYPE = {
  TODAY: "today",
  CUSTOM: "custom",
  LAST_24_HOURS: "last_24_hours",
  LAST_7_DAYS: "last_7_days",
  LAST_30_DAYS: "last_30_days",
  LAST_90_DAYS: "last_90_days",
  LAST_180_DAYS: "last_180_days",
  LAST_365_DAYS: "last_365_days",
  YESTERDAY: "yesterday",
  DAY_BEFORE_YESTERDAY: "day_before_yesterday"
} as const;

export const VIEWER_PERMISSIONS = {
  event: {
    eventSearch: false,
    eventPlayback: false
  },
  report: {
    reportView: false,
    reportAddEditDelete: false
  },
  setting: {
    deviceManagement: false,
    userManagement: false,
    assignSuperMember: false,
    assignAdminMember: false,
    assignOperatorMember: false,
    assignUserMember: false
  },
  dashboard: {
    dashboardMonitoring: true,
    dashboardSequence: true,
    dashboardCalendarSearch: true,
    dashboardAddEditDelete: false,
    widgetAddDeleteShowHide: false,
    widgetSetting: false,
    widgetRelocatingResizing: false,
    widgetLivePlayback: false
  }
} as const;

export const ROLE_TYPE = {
  SUPER_ADMIN: "Super Admin",
  ADMIN: "Admin",
  OPERATOR: "Operator",
  VIEWER: "Viewer"
};
export const ROLE_SORT_ORDER = [
  ROLE_TYPE.SUPER_ADMIN,
  ROLE_TYPE.ADMIN,
  ROLE_TYPE.OPERATOR,
  ROLE_TYPE.VIEWER
];

export const ALARM_BUBBLE_TYPE = {
  NO_HELMET: "No Helmet",
  DANGER: "Danger",
  RESTRICTED_AREA: "Restricted Area",
  FALL_SLIP: "Slip & fall",
  NO_VEST: "No Vest",
  STOPPED_VEHICLE: "Stopped vehicle",
  TRAFFIC_JAM: "Traffic jam"
} as const;

export const WIDGET_CATEGORY = {
  ALL: "all",
  MANAGEMENT_MAP: "management_map",
  PEOPLE_MANAGEMENT: "people_management",
  SEAMLESS_OPERATION: "seamless_operation",
  FACILITY_MANAGEMENT: "facility_management",
  SAFETY_MANAGEMENT: "safety_management",
  VEHICLE_MANAGEMENT: "vehicle_management"
} as const;

export const SETUP_MENU = {
  DEVICE: "device",
  EVENT: "event",
  USER: "user",
  LOG: "log",
  SYSTEM: "system"
} as const;

export const PASSWORD_VALID_RESULT_TYPE = {
  AT_LEAST_8_CHARACTER: "at_least_8_character",
  UP_TO_64_CHARACTER: "up_to_64_character",
  MUST_HAVE_2_DIFFERENT_TYPE: "must_have_2_different_type",
  MUST_HAVE_3_DIFFERENT_TYPE: "must_have_3_different_type",
  HAS_CONSECUTIVE_CHARACTER: "has_consecutive_character",
  HAS_REPEAT_CHARACTER: "has_repeat_character",
  USER_ID_NOT_USED_AS_PASSWORD: "userId_not_used_as_password"
} as const;

export const AUTHENTICATE_INPUT = {
  ID: "id",
  PASSWORD: "password",
  SAVE_FOR_FUTURE_SEARCH: "saveForFutureSearch"
} as const;

export const ORDER_KEY = {
  NAME: "name",
  MODEL: "model",
  MAC: "mac",
  STATUS: "status",
  IP: "ip"
} as const;

export const SORT_TYPE = {
  NONE: "NONE",
  ASCENDING: "ASCENDING",
  DESCENDING: "DESCENDING"
} as const;

export const WAVE_SYNC_STATUS = {
  LINKED: "Linked",
  LINK_DISCONNECT: "Disconnect"
} as const;

export const ON_CLOUD_STATUS = {
  LINKED: "Linked",
  LINK_DISCONNECT: "Disconnect"
} as const;

export const DEVICE_STATUS = {
  CONNECT: "Connect",
  DISCONNECT: "Disconnect"
} as const;

export const PAGINATION_DEFAULT_PER_PAGE = 50;
export const PAGE_DEFAULT = 0;

export const USER_NODE_TREE_TYPE = {
  GROUP: "group",
  USER: "user"
} as const;

export const VMS_TYPE = {
  WAVE: "WAVE",
  SSM: "SSM",
  NVR: "NVR"
} as const;

export const RECORDING_SOURCE = {
  NONE: "NONE",
  CAMERA: "CAMERA",
  WAVE: "WAVE",
  SSM: "SSM",
  NVR: "NVR"
} as const;

export const AI_TYPE = {
  PEOPLE_COUNT: "PEOPLE_COUNT",
  QUEUE_MANAGEMENT: "QUEUE_MANAGEMENT",
  HEATMAP: "HEATMAP",
  SLIP_AND_FALL: "SLIP_AND_FALL",
  TEMPERATURE_DETECTION: "TEMPERATURE_DETECTION",
  STOPPED_VEHICLE: "STOPPED_VEHICLE",
  TRAFFIC_JAM: "TRAFFIC_JAM",
  VEHICLE_COUNT: "VEHICLE_COUNT",
  CROWD_COUNT: "CROWD_COUNT",
  FORKLIFT_SPEED: "FORKLIFT_SPEED",
  SHOPPING_CART_COUNT: "SHOPPING_CART_COUNT",
  SHOPPING_CART_LOSS: "SHOPPING_CART_LOSS",
  SHOPPING_CART_QUEUE_MANAGEMENT: "SHOPPING_CART_QUEUE_MANAGEMENT",
  VEHICLE_QUEUE_MANAGEMENT: "VEHICLE_QUEUE_MANAGEMENT"
} as const;

export const BUTTON_TYPE = {
  OK: "OK",
  APPLY: "Apply",
  CANCEL: "Cancel",
  CLOSE: "Close",
  BACK: "Back"
} as const;

export const DEVICE_TYPE = {
  CAMERA: "CAMERA",
  VMS: "VMS"
} as const;

export const HEATMAP_TYPE = {
  COLOR: "COLOR",
  BW: "BW"
} as const;

export const RECEIPT_TYPE = {
  NONE: "NONE",
  REFUND: "REFUND",
  DISCOUNT: "DISCOUNT"
} as const;

export const MAP_OVERLAY_TYPE = {
  MAX: "max",
  HIGH: "high",
  MEDIUM: "medium",
  LOW: "low",
  NONE: "none"
} as const;

export const MAP_RETAIL_SUMMARY_TYPE = {
  NONE: "NONE",
  REFUND: "REFUND",
  COUPON: "COUPON"
} as const;

export const EVENT_TYPE = {
  NONE: "NONE",
  EVENT_ALL: "EVENT_ALL",
  SLIP_AND_FALL: "SLIP_AND_FALL",
  SHOPPING_CART_LOSS: "SHOPPING_CART_LOSS",
  TRAFFIC_JAM: "TRAFFIC_JAM",
  QUEUE_MANAGEMENT: "QUEUE_MANAGEMENT",
  MES: "MES",
  MES_ACTION: "MES_ACTION",
  TEMPERATURE: "TEMPERATURE",
  STOPPED_VEHICLE: "STOPPED_VEHICLE",
  FORKLIFT_SPEED: "FORKLIFT_SPEED"
} as const;

export const EVENT_PLAYER_TYPE = {
  LIVE: "Live",
  PLAYBACK: "Playback"
} as const;

export const ALARM_POPUP_NOTIFICATION = {
  ON: "ON",
  OFF: "OFF"
} as const;

export const AFTER_ACTION_DURATION_TIME = {
  FIVE_MINUTES: 5,
  TEN_MINUTES: 10,
  TWENTY_MINUTES: 20,
  THIRTY_MINUTES: 30,
  FORTY_MINUTES: 40,
  FIFTY_MINUTES: 50,
  SIXTY_MINUTES: 60
} as const;

export const QUEUE_LEVEL_TYPE = {
  NONE: "None",
  LOW: "Low",
  MEDIUM: "Medium",
  HIGH: "High",
  MAX: "Max"
} as const;

export const EVENT_STATUS = {
  DETECTED: "DETECTED",
  AFTER_ACTION: "AFTER_ACTION",
  NO_ACTION: "NO_ACTION",
  CONFIRMED: "CONFIRMED"
} as const;

export const TEMPERATURE_TYPE = {
  MINIMUM: "MINIMUM",
  MAXIMUM: "MAXIMUM",
  AVERAGE: "AVERAGE",
  MINMAX: "MINMAX"
} as const;

export const DETECTION_CONDITION = {
  ABOVE: "ABOVE",
  BELOW: "BELOW",
  INCREASE: "INCREASE",
  DECREASE: "DECREASE"
} as const;

export const VEHICLE_TYPE = {
  CAR: "CAR",
  BUS: "BUS",
  TRUCK: "TRUCK",
  MOTORCYCLE: "MOTORCYCLE",
  BICYCLE: "BICYCLE"
} as const;

export const DEVICE_RULE_MODE = {
  LEFT_TO_RIGHT_IN: "LeftToRightIn",
  RIGHT_TO_LEFT_IN: "RightToLeftIn"
} as const;

export const CONTEXT_MENU_DASHBOARD = {
  NEW: "new",
  DUPLICATE: "duplicate",
  TEMPLATES: "templates"
} as const;

export const TIME_INTERVAL = {
  DAY_1: "1D",
  DAY_7: "7D",
  HOUR_1: "1H",
  HOUR_12: "12H",
  MIN_1: "1M",
  MIN_5: "5M",
  MIN_15: "15M",
  MIN_30: "30M",
  MONTH_1: "1M"
} as const;

export const TYPE_TIME_INTERVAL = {
  HOUR: "HOUR",
  DAY: "DAY",
  WEEK: "WEEK",
  MONTH: "MONTH"
} as const;

export const LICENSE_REMAINING_DAYS = 30;
export const OVER_AVERAGE_CROWD_SAFETY = 500;

export const MAX_PIN_SET = 3;

export const OPTIONS_SETUP = {
  COMPARISON_DATE: "COMPARISON_DATE",
  ALARM: "ALARM",
  DURATION_TIME: "DURATION_TIME",
  LIST_VEHICLE: "LIST_VEHICLE",
  KPI: "Target",
  RESET_TIME: "RESET_TIME",
  CUMULATIVE_TYPE: "CUMULATIVE_TYPE"
} as const;

export const CUMULATIVE_TYPE = {
  TOTAL: "TOTAL",
  INTERVAL: "INTERVAL"
} as const;
export const listMonth = [
  {
    value: MONTH.JAN,
    label: MONTH.JAN
  },
  {
    value: MONTH.FEB,
    label: MONTH.FEB
  },
  {
    value: MONTH.MAR,
    label: MONTH.MAR
  },
  {
    value: MONTH.APR,
    label: MONTH.APR
  },
  {
    value: MONTH.MAY,
    label: MONTH.MAY
  },
  {
    value: MONTH.JUN,
    label: MONTH.JUN
  },
  {
    value: MONTH.JUL,
    label: MONTH.JUL
  },
  {
    value: MONTH.AUG,
    label: MONTH.AUG
  },
  {
    value: MONTH.SEP,
    label: MONTH.SEP
  },
  {
    value: MONTH.OCT,
    label: MONTH.OCT
  },
  {
    value: MONTH.NOV,
    label: MONTH.NOV
  },
  {
    value: MONTH.DEC,
    label: MONTH.DEC
  }
];

export enum SHOW_COUNTS_TYPE {
  TOTAL,
  TRAFFIC
}

export const TRAFFIC_TYPE = {
  UP: "UP",
  DOWN: "DOWN",
  UP_DOWN: "UP_DOWN",
  LEFT: "LEFT",
  RIGHT: "RIGHT",
  LEFT_RIGHT: "LEFT_RIGHT"
} as const;

export enum OVERLAY_TYPE {
  AREA_TRAFFIC = "areaTraffic",
  AREA_TRAFFIC_BY_TARGET = "areaTrafficByTarget",
  PEOPLE_COUNTING = "peopleCounting",
  PASS_TRAFFIC = "passTraffic",
  VEHICLE_COUNTING = "vehicleCount",
  SHOPPING_CART_PASS_TRAFFIC = "shoppingCartPassTraffic",
  SHOPPING_CART_AREA_TRAFFIC = "shoppingCartAreaTraffic",
  EVENT = "event",
  CROWD_COUNTING = "crowdCounting",
  EVENT_STATISTICS = "eventStatistics",
  NONE = "none"
}

export const PEOPLE_COUNT_MODE = {
  LEFT_TO_RIGHT_IN: "LeftToRightIn",
  RIGHT_TO_LEFT_IN: "RightToLeftIn"
} as const;

export const USER_STATUS = {
  ACTIVE: "ACTIVE",
  PENDING_ACTIVATION: "PENDING_ACTIVATION",
  INACTIVE: "INACTIVE",
  LOCKED: "LOCKED"
};

export const UTC_TIMEZONE_LIST = [
  {
    label: "(UTC-11:00) Midway Island",
    value: "Pacific/Midway"
  },
  {
    label: "(UTC-11:00) Samoa",
    value: "US/Samoa"
  },
  {
    label: "(UTC-10:00) Hawaii",
    value: "US/Hawaii"
  },
  {
    label: "(UTC-09:00) Alaska",
    value: "US/Alaska"
  },
  {
    label: "(UTC-08:00) Pacific Time (US & Canada)",
    value: "US/Pacific"
  },
  {
    label: "(UTC-08:00) Tijuana",
    value: "America/Tijuana"
  },
  {
    label: "(UTC-07:00) Arizona",
    value: "US/Arizona"
  },
  {
    label: "(UTC-07:00) Mountain Time (US & Canada)",
    value: "US/Mountain"
  },
  {
    label: "(UTC-07:00) Chihuahua",
    value: "America/Chihuahua"
  },
  {
    label: "(UTC-07:00) Mazatlan",
    value: "America/Mazatlan"
  },
  {
    label: "(UTC-06:00) Mexico City",
    value: "America/Mexico_City"
  },
  {
    label: "(UTC-06:00) Monterrey",
    value: "America/Monterrey"
  },
  {
    label: "(UTC-06:00) Saskatchewan",
    value: "Canada/Saskatchewan"
  },
  {
    label: "(UTC-06:00) Central Time (US & Canada)",
    value: "US/Central"
  },
  {
    label: "(UTC-05:00) Eastern Time (US & Canada)",
    value: "US/Eastern"
  },
  {
    label: "(UTC-05:00) Indiana (East)",
    value: "US/East-Indiana"
  },
  {
    label: "(UTC-05:00) Bogota",
    value: "America/Bogota"
  },
  {
    label: "(UTC-05:00) Lima",
    value: "America/Lima"
  },
  {
    label: "(UTC-04:30) Caracas",
    value: "America/Caracas"
  },
  {
    label: "(UTC-04:00) Atlantic Time (Canada)",
    value: "Canada/Atlantic"
  },
  {
    label: "(UTC-04:00) La Paz",
    value: "America/La_Paz"
  },
  {
    label: "(UTC-04:00) Santiago",
    value: "America/Santiago"
  },
  {
    label: "(UTC-03:30) Newfoundland",
    value: "Canada/Newfoundland"
  },
  {
    label: "(UTC-03:00) Buenos Aires",
    value: "America/Buenos_Aires"
  },
  {
    label: "(UTC-03:00) Greenland",
    value: "Greenland"
  },
  {
    label: "(UTC-02:00) Stanley",
    value: "Atlantic/Stanley"
  },
  {
    label: "(UTC-01:00) Azores",
    value: "Atlantic/Azores"
  },
  {
    label: "(UTC-01:00) Cape Verde Is.",
    value: "Atlantic/Cape_Verde"
  },
  {
    label: "(UTC) Casablanca",
    value: "Africa/Casablanca"
  },
  {
    label: "(UTC) Dublin",
    value: "Europe/Dublin"
  },
  {
    label: "(UTC) Lisbon",
    value: "Europe/Lisbon"
  },
  {
    label: "(UTC) London",
    value: "Europe/London"
  },
  {
    label: "(UTC) Monrovia",
    value: "Africa/Monrovia"
  },
  {
    label: "(UTC+01:00) Amsterdam",
    value: "Europe/Amsterdam"
  },
  {
    label: "(UTC+01:00) Belgrade",
    value: "Europe/Belgrade"
  },
  {
    label: "(UTC+01:00) Berlin",
    value: "Europe/Berlin"
  },
  {
    label: "(UTC+01:00) Bratislava",
    value: "Europe/Bratislava"
  },
  {
    label: "(UTC+01:00) Brussels",
    value: "Europe/Brussels"
  },
  {
    label: "(UTC+01:00) Budapest",
    value: "Europe/Budapest"
  },
  {
    label: "(UTC+01:00) Copenhagen",
    value: "Europe/Copenhagen"
  },
  {
    label: "(UTC+01:00) Ljubljana",
    value: "Europe/Ljubljana"
  },
  {
    label: "(UTC+01:00) Madrid",
    value: "Europe/Madrid"
  },
  {
    label: "(UTC+01:00) Paris",
    value: "Europe/Paris"
  },
  {
    label: "(UTC+01:00) Prague",
    value: "Europe/Prague"
  },
  {
    label: "(UTC+01:00) Rome",
    value: "Europe/Rome"
  },
  {
    label: "(UTC+01:00) Sarajevo",
    value: "Europe/Sarajevo"
  },
  {
    label: "(UTC+01:00) Skopje",
    value: "Europe/Skopje"
  },
  {
    label: "(UTC+01:00) Stockholm",
    value: "Europe/Stockholm"
  },
  {
    label: "(UTC+01:00) Vienna",
    value: "Europe/Vienna"
  },
  {
    label: "(UTC+01:00) Warsaw",
    value: "Europe/Warsaw"
  },
  {
    label: "(UTC+01:00) Zagreb",
    value: "Europe/Zagreb"
  },
  {
    label: "(UTC+02:00) Athens",
    value: "Europe/Athens"
  },
  {
    label: "(UTC+02:00) Bucharest",
    value: "Europe/Bucharest"
  },
  {
    label: "(UTC+02:00) Cairo",
    value: "Africa/Cairo"
  },
  {
    label: "(UTC+02:00) Harare",
    value: "Africa/Harare"
  },
  {
    label: "(UTC+02:00) Helsinki",
    value: "Europe/Helsinki"
  },
  {
    label: "(UTC+02:00) Istanbul",
    value: "Europe/Istanbul"
  },
  {
    label: "(UTC+02:00) Jerusalem",
    value: "Asia/Jerusalem"
  },
  {
    label: "(UTC+02:00) Kyiv",
    value: "Europe/Kiev"
  },
  {
    label: "(UTC+02:00) Minsk",
    value: "Europe/Minsk"
  },
  {
    label: "(UTC+02:00) Riga",
    value: "Europe/Riga"
  },
  {
    label: "(UTC+02:00) Sofia",
    value: "Europe/Sofia"
  },
  {
    label: "(UTC+02:00) Tallinn",
    value: "Europe/Tallinn"
  },
  {
    label: "(UTC+02:00) Vilnius",
    value: "Europe/Vilnius"
  },
  {
    label: "(UTC+03:00) Baghdad",
    value: "Asia/Baghdad"
  },
  {
    label: "(UTC+03:00) Kuwait",
    value: "Asia/Kuwait"
  },
  {
    label: "(UTC+03:00) Nairobi",
    value: "Africa/Nairobi"
  },
  {
    label: "(UTC+03:00) Riyadh",
    value: "Asia/Riyadh"
  },
  {
    label: "(UTC+03:00) Moscow",
    value: "Europe/Moscow"
  },
  {
    label: "(UTC+03:30) Tehran",
    value: "Asia/Tehran"
  },
  {
    label: "(UTC+04:00) Baku",
    value: "Asia/Baku"
  },
  {
    label: "(UTC+04:00) Volgograd",
    value: "Europe/Volgograd"
  },
  {
    label: "(UTC+04:00) Muscat",
    value: "Asia/Muscat"
  },
  {
    label: "(UTC+04:00) Tbilisi",
    value: "Asia/Tbilisi"
  },
  {
    label: "(UTC+04:00) Yerevan",
    value: "Asia/Yerevan"
  },
  {
    label: "(UTC+04:30) Kabul",
    value: "Asia/Kabul"
  },
  {
    label: "(UTC+05:00) Karachi",
    value: "Asia/Karachi"
  },
  {
    label: "(UTC+05:00) Tashkent",
    value: "Asia/Tashkent"
  },
  {
    label: "(UTC+05:30) Kolkata",
    value: "Asia/Kolkata"
  },
  {
    label: "(UTC+05:45) Kathmandu",
    value: "Asia/Kathmandu"
  },
  {
    label: "(UTC+06:00) Ekaterinburg",
    value: "Asia/Yekaterinburg"
  },
  {
    label: "(UTC+06:00) Almaty",
    value: "Asia/Almaty"
  },
  {
    label: "(UTC+06:00) Dhaka",
    value: "Asia/Dhaka"
  },
  {
    label: "(UTC+07:00) Novosibirsk",
    value: "Asia/Novosibirsk"
  },
  {
    label: "(UTC+07:00) Bangkok",
    value: "Asia/Bangkok"
  },
  {
    label: "(UTC+07:00) Jakarta",
    value: "Asia/Jakarta"
  },
  {
    label: "(UTC+08:00) Krasnoyarsk",
    value: "Asia/Krasnoyarsk"
  },
  {
    label: "(UTC+08:00) Chongqing",
    value: "Asia/Chongqing"
  },
  {
    label: "(UTC+08:00) Hong Kong",
    value: "Asia/Hong_Kong"
  },
  {
    label: "(UTC+08:00) Kuala Lumpur",
    value: "Asia/Kuala_Lumpur"
  },
  {
    label: "(UTC+08:00) Perth",
    value: "Australia/Perth"
  },
  {
    label: "(UTC+08:00) Singapore",
    value: "Asia/Singapore"
  },
  {
    label: "(UTC+08:00) Taipei",
    value: "Asia/Taipei"
  },
  {
    label: "(UTC+08:00) Ulaan Bataar",
    value: "Asia/Ulaanbaatar"
  },
  {
    label: "(UTC+08:00) Urumqi",
    value: "Asia/Urumqi"
  },
  {
    label: "(UTC+09:00) Irkutsk",
    value: "Asia/Irkutsk"
  },
  {
    label: "(UTC+09:00) Seoul",
    value: "Asia/Seoul"
  },
  {
    label: "(UTC+09:00) Tokyo",
    value: "Asia/Tokyo"
  },
  {
    label: "(UTC+09:30) Adelaide",
    value: "Australia/Adelaide"
  },
  {
    label: "(UTC+09:30) Darwin",
    value: "Australia/Darwin"
  },
  {
    label: "(UTC+10:00) Yakutsk",
    value: "Asia/Yakutsk"
  },
  {
    label: "(UTC+10:00) Brisbane",
    value: "Australia/Brisbane"
  },
  {
    label: "(UTC+10:00) Canberra",
    value: "Australia/Canberra"
  },
  {
    label: "(UTC+10:00) Guam",
    value: "Pacific/Guam"
  },
  {
    label: "(UTC+10:00) Hobart",
    value: "Australia/Hobart"
  },
  {
    label: "(UTC+10:00) Melbourne",
    value: "Australia/Melbourne"
  },
  {
    label: "(UTC+10:00) Port Moresby",
    value: "Pacific/Port_Moresby"
  },
  {
    label: "(UTC+10:00) Sydney",
    value: "Australia/Sydney"
  },
  {
    label: "(UTC+11:00) Vladivostok",
    value: "Asia/Vladivostok"
  },
  {
    label: "(UTC+12:00) Magadan",
    value: "Asia/Magadan"
  },
  {
    label: "(UTC+12:00) Auckland",
    value: "Pacific/Auckland"
  },
  {
    label: "(UTC+12:00) Fiji",
    value: "Pacific/Fiji"
  }
];

export const REPORT_WIDGET_TYPE = {
  TEXT: "TEXT",
  WIDGET: "WIDGET"
} as const;

export const DEFAULT_WIDGET_FILTERS = [
  WIDGET_TYPE.CUMULATE_PEOPLECOUNTS,
  WIDGET_TYPE.STAYING_PEOPLECOUNTS,
  WIDGET_TYPE.CUMULATE_SHOPPING_CART_COUNTS,
  WIDGET_TYPE.ENTRY_EXIT_PEOPLECOUNTS,
  WIDGET_TYPE.SLIP_AND_FALL,
  WIDGET_TYPE.CHECKOUT_QUEUE,
  WIDGET_TYPE.CUMULATE_VEHICLECOUNTS,
  WIDGET_TYPE.STOPPED_VEHICLE_STATISTICS,
  WIDGET_TYPE.VEHICLE_QUEUE,
  WIDGET_TYPE.RANK_PEOPLECOUNTS,
  WIDGET_TYPE.VEHICLE_QUEUE,
  WIDGET_TYPE.SHOPPING_CART_COUNT,
  WIDGET_TYPE.SHOPPING_CART_CHECKOUT_QUEUE,
  WIDGET_TYPE.FORKLIFT_SPEED_EVENT,
  WIDGET_TYPE.SHOPPING_CART_LOSS_EVENT,
  WIDGET_TYPE.SLIP_AND_FALL_STATISTICS,
  WIDGET_TYPE.FORKLIFT_SPEED_STATISTICS
];

export const WIDGET_CALENDAR_PERIOD_RANGE_TYPE = {
  TODAY: "today",
  CUSTOM: "custom",
  LAST_24_HOUR: "last_24_hours",
  LAST_7_DAY: "last_7_days",
  LAST_30_DAY: "last_30_days",
  LAST_90_DAY: "last_90_days",
  LAST_180_DAY: "last_180_days",
  LAST_365_DAY: "last_365_days",
  YESTERDAY: "yesterday",
  DAY_BEFORE_YESTERDAY: "day_before_yesterday"
} as const;

export const REPORT_BODY_SEGMENTS_LIMIT = 6;

export const reportTextEditor = {
  DEFAULT_EDITOR_TEXT: "<p>Report description</p>",
  BLANK_TEXT_HTML: ["<h2><br></h2>", "<p><br></p>"]
};

export const DATE_PERIOD = {
  TODAY: "TODAY",
  CUSTOM: "CUSTOM",
  LAST_24_HOUR: "LAST_24_HOUR",
  LAST_7_DAY: "LAST_7_DAY",
  LAST_30_DAY: "LAST_30_DAY",
  LAST_90_DAY: "LAST_90_DAY",
  YESTERDAY: "YESTERDAY",
  DAY_BEFORE_YESTERDAY: "DAY_BEFORE_YESTERDAY",
  LAST_180_DAY: "LAST_180_DAY",
  LAST_365_DAY: "LAST_365_DAY"
} as const;

export const MAP_TIME_PERIOD = {
  "1H": "TODAY",
  "1D": "LAST_7_DAY",
  "7D": "LAST_90_DAY",
  "1M": "LAST_180_DAY"
} as const;

export const REDIRECT_PARAM = "redirect_app_url";

export const REPORT_PERIOD_INTERVAL_TYPES = {
  "1H": "Hourly",
  "1D": "Daily",
  "7D": "Weekly",
  "1M": "Monthly"
} as const;

export const CALCULATIONS = {
  SUM: "SUM",
  AVG: "AVG",
  EACH: "EACH"
};

export const SINGLE_WIDGET_SUPPORT: string[] = [
  WIDGET_TYPE.CHECKOUT_QUEUE,
  WIDGET_TYPE.VEHICLE_QUEUE,
  WIDGET_TYPE.SHOPPING_CART_CHECKOUT_QUEUE
];

export const REPORT_WIDGET_DATA_TYPE = {
  AVERAGE_LENGTH: "AVERAGE_LENGTH",
  AVERAGE_WAIT_TIME: "AVERAGE_WAIT_TIME"
};

export const CLS_NODE_GROUP = "tree-node-group";
export const CLS_NODE_DASHBOARD = "tree-node-dashboard";
export const POSTFIX_ROOT_DASHBOARD = "root-dashboard";
export const CLS_NODE_ROOT_DASHBOARD = `tree-node-${POSTFIX_ROOT_DASHBOARD}`;

export const InputEditorColorPalate = [
  "#4891FF",
  "#00B960",
  "#FFB400",
  "#FF6D10",
  "#FA4D4D",
  "#FF5082",
  "#B93CF0",
  "#8333FC",
  "#5A44EB",
  "#141414",
  "#353739",
  "#56595B",
  "#7C868F",
  "#959BA1",
  "#FFFFFF"
];

export const WIDGET_CHIP_COLOR_PALATE = [
  "system-yellow",
  "system-green",
  "system-blue",
  "system-orange",
  "system-emerald",
  "system-indigo",
  "system-pink",
  "system-violet"
];

export const WIDGET_CHIP_RULES = {
  RULE_7_8: "7_8_RULE",
  RULE_5_6: "RULE_5_6",
  RULE_3_4: "RULE_3_4",
  RULE_2: "RULE_2",
  RULE_1: "RULE_1"
};

export const WIDGET_RULE_CHIP_SIZES = {
  SMALL: "small",
  NORMAL: "normal",
  MEDIUM: "medium",
  LARGE: "large",
  EXTRA_LARGE: "extraLarge"
};
