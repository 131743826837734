import { createSlice } from "@reduxjs/toolkit";
import { NotificationType } from "@queries/Notifications";

const initialState: NotificationType = {
  id: "",
  eventType: "REPORT_SHARE",
  status: "DETECTED",
  timestamp: "",
  type: "",
  read: false,
  eventName: "",
  userId: "",
  eventId: "",
  info: {
    failedReason: "",
    isSharedSuccess: false,
    reportId: "",
    reportTitle: "",
    sharedByUserId: "",
    sharedByUsername: "",
    sharedToUserId: ""
  }
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNewNotification: (_, action) => {
      return action.payload;
    }
  }
});

export const { setNewNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
