import { SVGProps, HTMLAttributes, createElement } from "react";
import styled from "styled-components";

import { ReactComponent as _AddReportContentIcon } from "./svg/add-report-content-icon.svg";
import { ReactComponent as _ChartTypeBarIcon } from "./svg/chart-type-bar-icon.svg";
import { ReactComponent as _ChartTypeBarStackIcon } from "./svg/chart-type-bar-stack-icon.svg";
import { ReactComponent as _ChartTypeLineIcon } from "./svg/chart-type-line-icon.svg";
import { ReactComponent as _ChartTypeMultipleLineIcon } from "./svg/chart-type-multiple-line-icon.svg";
import { ReactComponent as _ChartTypeSingleLineIcon } from "./svg/chart-type-single-line-icon.svg";
import { ReactComponent as _ChartTypePieDoughuntIcon } from "./svg/chart-type-pie-doughunt-icon.svg";
import { ReactComponent as _ChartTypePieIcon } from "./svg/chart-type-pie-icon.svg";
import { ReactComponent as _DeleteReportWidget } from "./svg/delete-report-widget.svg";
import { ReactComponent as _DragIcon } from "./svg/drag-icon.svg";
import { ReactComponent as _IconAccident } from "./svg/icon-accident.svg";
import { ReactComponent as _IconAdd } from "./svg/icon-add.svg";
import { ReactComponent as _IconAdmin } from "./svg/icon-admin.svg";
import { ReactComponent as _IconAfterAction } from "./svg/icon-after-action.svg";
import { ReactComponent as _IconAlarm } from "./svg/icon-alarm.svg";
import { ReactComponent as _IconAlarmLine } from "./svg/icon-alarm-line.svg";
import { ReactComponent as _IconArea } from "./svg/icon-area.svg";
import { ReactComponent as _IconArrow } from "./svg/icon-arrow.svg";
import { ReactComponent as _IconArrowDown } from "./svg/icon-arrow-down.svg";
import { ReactComponent as _IconArrowDownCalendar } from "./svg/icon-arrow-down-calendar.svg";
import { ReactComponent as _IconArrowDownFill } from "./svg/icon-arrow-down-fill.svg";
import { ReactComponent as _IconArrowDownLine } from "./svg/icon-arrow-down-line.svg";
import { ReactComponent as _IconArrowLeft } from "./svg/icon-arrow-left.svg";
import { ReactComponent as _IconArrowLeftLine } from "./svg/icon-arrow-left-line.svg";
import { ReactComponent as _IconArrowRight } from "./svg/icon-arrow-right.svg";
import { ReactComponent as _IconArrowRightLine } from "./svg/icon-arrow-right-line.svg";
import { ReactComponent as _IconArrowUp } from "./svg/icon-arrow-up.svg";
import { ReactComponent as _IconArrowUpFill } from "./svg/icon-arrow-up-fill.svg";
import { ReactComponent as _IconArrowUpLine } from "./svg/icon-arrow-up-line.svg";
import { ReactComponent as _IconAuthenticate } from "./svg/icon-authenticate.svg";
import { ReactComponent as _IconBagFill } from "./svg/icon-bag-fill.svg";
import { ReactComponent as _IconBetaLogo } from "./svg/icon-beta-logo.svg";
import { ReactComponent as _IconBicycle } from "./svg/icon-bicycle.svg";
import { ReactComponent as _IconBookmark } from "./svg/icon-bookmark.svg";
import { ReactComponent as _IconBox } from "./svg/icon-box.svg";
import { ReactComponent as _IconBoxing } from "./svg/icon-boxing.svg";
import { ReactComponent as _IconBus } from "./svg/icon-bus.svg";
import { ReactComponent as _IconCCTV } from "./svg/icon-cctv.svg";
import { ReactComponent as _IconCalculator } from "./svg/icon-calculator.svg";
import { ReactComponent as _IconCalendar } from "./svg/icon-calendar.svg";
import { ReactComponent as _IconCam } from "./svg/icon-cam.svg";
import { ReactComponent as _IconCamNone } from "./svg/icon-cam-none.svg";
import { ReactComponent as _IconCamOff } from "./svg/icon-cam-off.svg";
import { ReactComponent as _IconCamOn } from "./svg/icon-cam-on.svg";
import { ReactComponent as _IconCamcorderAvailable } from "./svg/icon-camcorder-available.svg";
import { ReactComponent as _IconCamcorderDisable } from "./svg/icon-camcorder-disable.svg";
import { ReactComponent as _IconCameraFill } from "./svg/icon-camera-fill.svg";
import { ReactComponent as _IconCameraFillDisable } from "./svg/icon-camera-fill-disable.svg";
import { ReactComponent as _IconCar } from "./svg/icon-car.svg";
import { ReactComponent as _IconCarEvent } from "./svg/icon-car-event.svg";
import { ReactComponent as _IconCarFill } from "./svg/icon-car-fill.svg";
import { ReactComponent as _IconCard } from "./svg/icon-card.svg";
import { ReactComponent as _IconCartFill } from "./svg/icon-cart-fill.svg";
import { ReactComponent as _IconCaution } from "./svg/icon-caution.svg";
import { ReactComponent as _IconCheckCircle } from "./svg/icon-check-circle.svg";
import { ReactComponent as _IconCheckLine } from "./svg/icon-check-line.svg";
import { ReactComponent as _IconCheckPolygon } from "./svg/icon-check-polygon.svg";
import { ReactComponent as _IconCheckTick } from "./svg/icon-check-tick.svg";
import { ReactComponent as _IconCircle } from "./svg/icon-circle.svg";
import { ReactComponent as _IconClose } from "./svg/icon-close.svg";
import { ReactComponent as _IconCloseSmall } from "./svg/icon-close-small.svg";
import { ReactComponent as _IconCloud } from "./svg/icon-cloud.svg";
import { ReactComponent as _IconCloudActive } from "./svg/icon-cloud-active.svg";
import { ReactComponent as _IconCloudDeactive } from "./svg/icon-cloud-deactive.svg";
import { ReactComponent as _IconCloudFill } from "./svg/icon-cloud-fill.svg";
import { ReactComponent as _IconCloudPortal } from "./svg/icon-cloud-portal.svg";
import { ReactComponent as _IconColorModeDark } from "./svg/icon-color-mode-dark.svg";
import { ReactComponent as _IconColorModeLight } from "./svg/icon-color-mode-light.svg";
import { ReactComponent as _IconColorModeSystem } from "./svg/icon-color-mode-system.svg";
import { ReactComponent as _IconComputer } from "./svg/icon-computer.svg";
import { ReactComponent as _IconConeFill } from "./svg/icon-cone-fill.svg";
import { ReactComponent as _IconConfirmed } from "./svg/icon-confirmed.svg";
import { ReactComponent as _IconConnect } from "./svg/icon-connect.svg";
import { ReactComponent as _IconCopy } from "./svg/icon-copy.svg";
import { ReactComponent as _IconCrowdSafetyStatistics } from "./svg/icon-crowd-safety-statistics.svg";
import { ReactComponent as _IconCurrentLocationDot } from "./svg/icon-current-location-dot.svg";
import { ReactComponent as _IconDanger } from "./svg/icon-danger.svg";
import { ReactComponent as _IconDashboardSequence } from "./svg/icon-dashboard-sequence.svg";
import { ReactComponent as _IconDataCircle } from "./svg/icon-data-circle.svg";
import { ReactComponent as _IconDataFill } from "./svg/icon-data-fill.svg";
import { ReactComponent as _IconDataLine } from "./svg/icon-data-line.svg";
import { ReactComponent as _IconDatatypeCreate } from "./svg/icon-datatype-create.svg";
import { ReactComponent as _IconDelete } from "./svg/icon-delete.svg";
import { ReactComponent as _IconDeleteReport } from "./svg/icon-delete-report.svg";
import { ReactComponent as _IconDetecting } from "./svg/icon-detecting.svg";
import { ReactComponent as _IconDetectingLight } from "./svg/icon-detecting-light.svg";
import { ReactComponent as _IconDevice } from "./svg/icon-device.svg";
import { ReactComponent as _IconDomeCamera } from "./svg/icon-dome-camera.svg";
import { ReactComponent as _IconDone } from "./svg/icon-done.svg";
import { ReactComponent as _IconDown } from "./svg/icon-down.svg";
import { ReactComponent as _IconDownFill } from "./svg/icon-down-fill.svg";
import { ReactComponent as _IconEdit } from "./svg/icon-edit.svg";
import { ReactComponent as _IconEditSmall } from "./svg/icon-edit-small.svg";
import { ReactComponent as _IconEmail } from "./svg/icon-email.svg";
import { ReactComponent as _IconEmphasis } from "./svg/icon-emphasis.svg";
import { ReactComponent as _IconEvent } from "./svg/icon-event.svg";
import { ReactComponent as _IconEventActive } from "./svg/icon-event-active.svg";
import { ReactComponent as _IconEventTypeAll } from "./svg/icon-event-type-all.svg";
import { ReactComponent as _IconExpansionWidgetStyle } from "./svg/icon-expansion-widget-style.svg";
import { ReactComponent as _IconExport } from "./svg/icon-export.svg";
import { ReactComponent as _IconEyeOffLine } from "./svg/icon-eye-off-line.svg";
import { ReactComponent as _IconEyeOnLine } from "./svg/icon-eye-on-line.svg";
import { ReactComponent as _IconFactory } from "./svg/icon-factory.svg";
import { ReactComponent as _IconFileAddFill } from "./svg/icon-file-add-fill.svg";
import { ReactComponent as _IconFilter } from "./svg/icon-filter.svg";
import { ReactComponent as _IconFilterAllEvent } from "./svg/icon-filter-all-event.svg";
import { ReactComponent as _IconFix } from "./svg/icon-fix.svg";
import { ReactComponent as _IconFloatingAdd } from "./svg/icon-floating-add.svg";
import { ReactComponent as _IconFloatingClose } from "./svg/icon-floating-close.svg";
import { ReactComponent as _IconFolder } from "./svg/icon-folder.svg";
import { ReactComponent as _IconFolderCharacterFill } from "./svg/icon-folder-character-fill.svg";
import { ReactComponent as _IconFolderOpenLine } from "./svg/icon-folder-open-line.svg";
import { ReactComponent as _IconForklift } from "./svg/icon-forklift.svg";
import { ReactComponent as _IconGPS } from "./svg/icon-gps.svg";
import { ReactComponent as _IconGreenDot } from "./svg/icon-green-dot.svg";
import { ReactComponent as _IconGrid } from "./svg/icon-grid.svg";
import { ReactComponent as _IconGroup } from "./svg/icon-group.svg";
import { ReactComponent as _IconGroupAdd } from "./svg/icon-group-add.svg";
import { ReactComponent as _IconHeatmap } from "./svg/icon-heatmap.svg";
import { ReactComponent as _IconHelmet } from "./svg/icon-helmet.svg";
import { ReactComponent as _IconHelp } from "./svg/icon-help.svg";
import { ReactComponent as _IconImageUpload } from "./svg/icon-image-upload.svg";
import { ReactComponent as _IconImportDevice } from "./svg/icon-import-device.svg";
import { ReactComponent as _IconInbDashboardFill } from "./svg/icon-inb-dashboard-fill.svg";
import { ReactComponent as _IconInbDashboardLine } from "./svg/icon-inb-dashboard-line.svg";
import { ReactComponent as _IconInbEventFill } from "./svg/icon-inb-event-fill.svg";
import { ReactComponent as _IconInbEventLine } from "./svg/icon-inb-event-line.svg";
import { ReactComponent as _IconInbReportFill } from "./svg/icon-inb-report-fill.svg";
import { ReactComponent as _IconInbReportLine } from "./svg/icon-inb-report-line.svg";
import { ReactComponent as _IconInbSettingFill } from "./svg/icon-inb-setting-fill.svg";
import { ReactComponent as _IconInbSettingLine } from "./svg/icon-inb-setting-line.svg";
import { ReactComponent as _IconIndeterminate } from "./svg/icon-indeterminate.svg";
import { ReactComponent as _IconInfo } from "./svg/icon-info.svg";
import { ReactComponent as _IconInfoBig } from "./svg/icon-info-big.svg";
import { ReactComponent as _IconKey } from "./svg/icon-key.svg";
import { ReactComponent as _IconLaptop } from "./svg/icon-laptop.svg";
import { ReactComponent as _IconLayer } from "./svg/icon-layer.svg";
import { ReactComponent as _IconLeftCounts } from "./svg/icon-left-counts.svg";
import { ReactComponent as _IconLens } from "./svg/icon-lens.svg";
import { ReactComponent as _IconLightning } from "./svg/icon-lightning.svg";
import { ReactComponent as _IconLinkDisconnected } from "./svg/icon-link-disconnected.svg";
import { ReactComponent as _IconLinked } from "./svg/icon-linked.svg";
import { ReactComponent as _IconList } from "./svg/icon-list.svg";
import { ReactComponent as _IconLive } from "./svg/icon-live.svg";
import { ReactComponent as _IconLoader } from "./svg/icon-loader.svg";
import { ReactComponent as _IconLockFill } from "./svg/icon-lock-fill.svg";
import { ReactComponent as _IconLogoBig } from "./svg/icon-logo-big.svg";
import { ReactComponent as _IconLogoLogin } from "./svg/icon-logo-login.svg";
import { ReactComponent as _IconLogout } from "./svg/icon-logout.svg";
import { ReactComponent as _IconMainWidgetStyle } from "./svg/icon-main-widget-style.svg";
import { ReactComponent as _IconMapAdd } from "./svg/icon-map-add.svg";
import { ReactComponent as _IconMapEvent } from "./svg/icon-map-event.svg";
import { ReactComponent as _IconMapFill } from "./svg/icon-map-fill.svg";
import { ReactComponent as _IconMapNew } from "./svg/icon-map-new.svg";
import { ReactComponent as _IconMapPinFill } from "./svg/icon-map-pin-fill.svg";
import { ReactComponent as _IconMaster } from "./svg/icon-master.svg";
import { ReactComponent as _IconMesManagement } from "./svg/icon-mes-management.svg";
import { ReactComponent as _IconMesManagementDone } from "./svg/icon-mes-management-done.svg";
import { ReactComponent as _IconMesManagementError } from "./svg/icon-mes-management-error.svg";
import { ReactComponent as _IconMesManagementNoAction } from "./svg/icon-mes-management-no-action.svg";
import { ReactComponent as _IconMessageLine } from "./svg/icon-message-line.svg";
import { ReactComponent as _IconMinus } from "./svg/icon-minus.svg";
import { ReactComponent as _IconMoney } from "./svg/icon-money.svg";
import { ReactComponent as _IconMonitor } from "./svg/icon-monitor.svg";
import { ReactComponent as _IconMore } from "./svg/icon-more.svg";
import { ReactComponent as _IconMoreSmall } from "./svg/icon-more-small.svg";
import { ReactComponent as _IconMotorcycle } from "./svg/icon-motorcycle.svg";
import { ReactComponent as _IconNew } from "./svg/icon-new.svg";
import { ReactComponent as _IconNoAction } from "./svg/icon-no-action.svg";
import { ReactComponent as _IconNoData } from "./svg/icon-no-data.svg";
import { ReactComponent as _IconNoDevice } from "./svg/icon-no-device.svg";
import { ReactComponent as _IconNoPlayer } from "./svg/icon-no-player.svg";
import { ReactComponent as _IconNoResult } from "./svg/icon-no-result.svg";
import { ReactComponent as _IconNoUse } from "./svg/icon-no-use.svg";
import { ReactComponent as _IconNonCheck } from "./svg/icon-non-check.svg";
import { ReactComponent as _IconNoneFill } from "./svg/icon-none-fill.svg";
import { ReactComponent as _IconNotUse } from "./svg/icon-not-use.svg";
import { ReactComponent as _IconOiling } from "./svg/icon-oiling.svg";
import { ReactComponent as _IconOrganization } from "./svg/icon-organization.svg";
import { ReactComponent as _IconParking } from "./svg/icon-parking.svg";
import { ReactComponent as _IconPeople } from "./svg/icon-people.svg";
import { ReactComponent as _IconPeopleCount } from "./svg/icon-people-count.svg";
import { ReactComponent as _IconPeopleCounting } from "./svg/icon-people-counting.svg";
import { ReactComponent as _IconPersonWalking } from "./svg/icon-person-walking.svg";
import { ReactComponent as _IconPersonWalkingCircle } from "./svg/icon-person-walking-circle.svg";
import { ReactComponent as _IconPinCharacterFill } from "./svg/icon-pin-character-fill.svg";
import { ReactComponent as _IconPinFill } from "./svg/icon-pin-fill.svg";
import { ReactComponent as _IconPinPushLine } from "./svg/icon-pin-push-line.svg";
import { ReactComponent as _IconPlay } from "./svg/icon-play.svg";
import { ReactComponent as _IconPlayBack } from "./svg/icon-play-back.svg";
import { ReactComponent as _IconPlayButton } from "./svg/icon-play-button.svg";
import { ReactComponent as _IconPlayLine } from "./svg/icon-play-line.svg";
import { ReactComponent as _IconPlus } from "./svg/icon-plus.svg";
import { ReactComponent as _IconPlusClaim } from "./svg/icon-plus-claim.svg";
import { ReactComponent as _IconPos } from "./svg/icon-pos.svg";
import { ReactComponent as _IconProfileFill } from "./svg/icon-profile-fill.svg";
import { ReactComponent as _IconProtect } from "./svg/icon-protect.svg";
import { ReactComponent as _IconQuestionMark } from "./svg/icon-question-mark.svg";
import { ReactComponent as _IconQueueManagement } from "./svg/icon-queue-management.svg";
import { ReactComponent as _IconReceipt } from "./svg/icon-receipt.svg";
import { ReactComponent as _IconReceiptAvailable } from "./svg/icon-receipt-available.svg";
import { ReactComponent as _IconReceiptDisable } from "./svg/icon-receipt-disable.svg";
import { ReactComponent as _IconRedDot } from "./svg/icon-red-dot.svg";
import { ReactComponent as _IconRefresh } from "./svg/icon-refresh.svg";
import { ReactComponent as _IconRefreshLine } from "./svg/icon-refresh-line.svg";
import { ReactComponent as _IconReset } from "./svg/icon-reset.svg";
import { ReactComponent as _IconReturnArrow } from "./svg/icon-return-arrow.svg";
import { ReactComponent as _IconRightArrow } from "./svg/icon-right-arrow.svg";
import { ReactComponent as _IconRightCounts } from "./svg/icon-right-counts.svg";
import { ReactComponent as _IconRightTopArrow } from "./svg/icon-right-top-arrow.svg";
import { ReactComponent as _IconRoot } from "./svg/icon-root.svg";
import { ReactComponent as _IconRootFill } from "./svg/icon-root-fill.svg";
import { ReactComponent as _IconRule } from "./svg/icon-rule.svg";
import { ReactComponent as _IconRuleForklift } from "./svg/icon-rule-forklift.svg";
import { ReactComponent as _IconRuleQueueManagement } from "./svg/icon-rule-queue-management.svg";
import { ReactComponent as _IconRuleSmall } from "./svg/icon-rule-small.svg";
import { ReactComponent as _IconSales } from "./svg/icon-sales.svg";
import { ReactComponent as _IconSearch } from "./svg/icon-search.svg";
import { ReactComponent as _IconSelectRule } from "./svg/icon-select-rule.svg";
import { ReactComponent as _IconSequence } from "./svg/icon-sequence.svg";
import { ReactComponent as _IconSetting } from "./svg/icon-setting.svg";
import { ReactComponent as _IconSetting84 } from "./svg/icon-setting84.svg";
import { ReactComponent as _IconShareLine } from "./svg/icon-share-line.svg";
import { ReactComponent as _IconShopFill } from "./svg/icon-shop-fill.svg";
import { ReactComponent as _IconShoppingCart } from "./svg/icon-shopping-cart.svg";
import { ReactComponent as _IconShoppingCartCircle } from "./svg/icon-shopping-cart-circle.svg";
import { ReactComponent as _IconShoppingCartLine } from "./svg/icon-shopping-cart-line.svg";
import { ReactComponent as _IconShow } from "./svg/icon-show.svg";
import { ReactComponent as _IconSightMind } from "./svg/icon-sight-mind.svg";
import { ReactComponent as _IconSlipAndFall } from "./svg/icon-slip-and-fall.svg";
import { ReactComponent as _IconSlipAndFallMulti } from "./svg/icon-slip-and-fall-multi.svg";
import { ReactComponent as _IconSlipAndFallSingle } from "./svg/icon-slip-and-fall-single.svg";
import { ReactComponent as _IconSlipFall } from "./svg/icon-slip-fall.svg";
import { ReactComponent as _IconSort } from "./svg/icon-sort.svg";
import { ReactComponent as _IconSpeechBubble } from "./svg/icon-speech-bubble.svg";
import { ReactComponent as _IconSpeeding } from "./svg/icon-speeding.svg";
import { ReactComponent as _IconStopLine } from "./svg/icon-stop-line.svg";
import { ReactComponent as _IconSubtract } from "./svg/icon-subtract.svg";
import { ReactComponent as _IconSubtractLine } from "./svg/icon-subtract-line.svg";
import { ReactComponent as _IconTempFill } from "./svg/icon-temp-fill.svg";
import { ReactComponent as _IconTemperature } from "./svg/icon-temperature.svg";
import { ReactComponent as _IconTemperatureEvent } from "./svg/icon-temperature-event.svg";
import { ReactComponent as _IconThumbnailPlay } from "./svg/icon-thumbnail-play.svg";
import { ReactComponent as _IconTime } from "./svg/icon-time.svg";
import { ReactComponent as _IconToiletMan } from "./svg/icon-toilet-man.svg";
import { ReactComponent as _IconToiletWoman } from "./svg/icon-toilet-woman.svg";
import { ReactComponent as _IconTool } from "./svg/icon-tool.svg";
import { ReactComponent as _IconTopRightArrow } from "./svg/icon-top-right-arrow.svg";
import { ReactComponent as _IconTrafficJamEvent } from "./svg/icon-traffic-jam-event.svg";
import { ReactComponent as _IconTrafficJamEventMulti } from "./svg/icon-traffic-jam-event-multi.svg";
import { ReactComponent as _IconTrash } from "./svg/icon-trash.svg";
import { ReactComponent as _IconTreeViewCheck } from "./svg/icon-tree-view-check.svg";
import { ReactComponent as _IconTreeViewHalfCheck } from "./svg/icon-tree-view-half-check.svg";
import { ReactComponent as _IconTreeViewUnCheck } from "./svg/icon-tree-view-un-check.svg";
import { ReactComponent as _IconTruck } from "./svg/icon-truck.svg";
import { ReactComponent as _IconTruckFill } from "./svg/icon-truck-fill.svg";
import { ReactComponent as _IconUnlockFill } from "./svg/icon-unlock-fill.svg";
import { ReactComponent as _IconUp } from "./svg/icon-up.svg";
import { ReactComponent as _IconUpCounts } from "./svg/icon-up-counts.svg";
import { ReactComponent as _IconUpFill } from "./svg/icon-up-fill.svg";
import { ReactComponent as _IconUpload } from "./svg/icon-upload.svg";
import { ReactComponent as _IconUserAdd } from "./svg/icon-user-add.svg";
import { ReactComponent as _IconUserLogout } from "./svg/icon-user-logout.svg";
import { ReactComponent as _IconUserProfile } from "./svg/icon-user-profile.svg";
import { ReactComponent as _IconVehicle } from "./svg/icon-vehicle.svg";
import { ReactComponent as _IconVehicleCircle } from "./svg/icon-vehicle-circle.svg";
import { ReactComponent as _IconVehicleQueue } from "./svg/icon-vehicle-queue.svg";
import { ReactComponent as _IconVest } from "./svg/icon-vest.svg";
import { ReactComponent as _IconVideoCamera } from "./svg/icon-video-camera.svg";
import { ReactComponent as _IconVideoCameraAvailable } from "./svg/icon-video-camera-available.svg";
import { ReactComponent as _IconVideoCameraDisable } from "./svg/icon-video-camera-disable.svg";
import { ReactComponent as _IconVideoEnterFullscreen } from "./svg/icon-video-enter-fullscreen.svg";
import { ReactComponent as _IconVideoExitFullscreen } from "./svg/icon-video-exit-fullscreen.svg";
import { ReactComponent as _IconVideoPause } from "./svg/icon-video-pause.svg";
import { ReactComponent as _IconVideoPlay } from "./svg/icon-video-play.svg";
import { ReactComponent as _IconVideoSkip } from "./svg/icon-video-skip.svg";
import { ReactComponent as _IconVideoStop } from "./svg/icon-video-stop.svg";
import { ReactComponent as _IconWaveSync } from "./svg/icon-wave-sync.svg";
import { ReactComponent as _IconWaveSyncDeactive } from "./svg/icon-wave-sync-deactive.svg";
import { ReactComponent as _IconWaveSyncLogout } from "./svg/icon-wave-sync-logout.svg";
import { ReactComponent as _IconWidgetForklift } from "./svg/icon-widget-forklift.svg";
import { ReactComponent as _IconWifi } from "./svg/icon-wifi.svg";
import { ReactComponent as _IconWorker } from "./svg/icon-worker.svg";
import { ReactComponent as _IconWorkerAll } from "./svg/icon-worker-all.svg";
import { ReactComponent as _IconWorkerDay } from "./svg/icon-worker-day.svg";
import { ReactComponent as _IconWorkshop } from "./svg/icon-workshop.svg";
import { ReactComponent as _IconYellowDot } from "./svg/icon-yellow-dot.svg";
import { ReactComponent as _IconYoutube } from "./svg/icon-youtube.svg";
import { ReactComponent as _IconZoomIn } from "./svg/icon-zoom-in.svg";
import { ReactComponent as _IconZoomOut } from "./svg/icon-zoom-out.svg";
import { ReactComponent as _MixChartLineAndBar } from "./svg/mix-chart-line-and-bar.svg";
import { ReactComponent as _MixedChartLineAndArea } from "./svg/mixed-chart-line-and-area.svg";
import { ReactComponent as _ReportChartContentIcon } from "./svg/report-chart-content-icon.svg";
import { ReactComponent as _ReportDashboardMore } from "./svg/report-dashboard-more.svg";
import { ReactComponent as _ReportTextContentIcon } from "./svg/report-text-content-icon.svg";
import { ReactComponent as _ReportWidgetEdit } from "./svg/report-widget-edit.svg";
import { ReactComponent as _SelectWidgetIcon } from "./svg/select-widget-icon.svg";
import { ReactComponent as _SendEmailVectorIcon } from "./svg/send-email-vector-icon.svg";
import { ReactComponent as _HandleBar } from "./svg/handle-bar.svg";
import { ReactComponent as _IconImageNotAvailable } from "./svg/icon-image-not-available.svg";
import { ReactComponent as _ReportHeatmap } from "./svg/icon-report-heatmap.svg";
import { ReactComponent as _IconTreeArrow } from "./svg/icon-tree-arrow.svg";
import { ReactComponent as _IconTreeDashboard } from "./svg/icon-tree-dashboard.svg";
import { ReactComponent as _IconTreeFolder } from "./svg/icon-tree-folder.svg";
import { ReactComponent as _IconTreeFolderOpen } from "./svg/icon-tree-folder-open.svg";
import { ReactComponent as _IconMoreMenu } from "./svg/icon-more-menu.svg";
import { ReactComponent as _IconTreeFold } from "./svg/icon-tree-fold.svg";
import { ReactComponent as _SightMindLogo } from "./svg/sightmind-logo.svg";
import { ReactComponent as _HorizontalBar } from "./svg/icon-horizontal-bar.svg";
import { ReactComponent as _TopCount1 } from "./svg/report-widget-top-count-1.svg";
import { ReactComponent as _TopCount2 } from "./svg/report-widget-top-count-2.svg";
import { ReactComponent as _TopCount3 } from "./svg/report-widget-top-count-3.svg";
import { ReactComponent as _InfoIcon } from "./svg/info-icon.svg";
import { ReactComponent as _IconFilterOutline } from "./svg/icon-filter-outline.svg";
import { ReactComponent as _IconProfileLine } from "./svg/icon-profile-line.svg";
import { ReactComponent as _SightMindIconLogo } from "./svg/sightmind-icon-logo.svg";
import { ReactComponent as _ReportFile } from "./svg/report-file.svg";
import { ReactComponent as _IconShare } from "./svg/icon-share.svg";
import { ReactComponent as _IconArrowSolidChevronUp } from "./svg/arrow-solid-chevron-up.svg";
import { ReactComponent as _IconArrowSolidChevronDown } from "./svg/arrow-solid-chevron-down.svg";
import { ReactComponent as _IconArrowChevronDown } from "./svg/arrow-chevron-down.svg";
import { ReactComponent as _IconArrowChevronUp } from "./svg/arrow-chevron-up.svg";
import { ReactComponent as _IconArrowTimeInputChevronUp } from "./svg/icon-arrow-time-input-chevron-up.svg";
import { ReactComponent as _IconArrowTimeInputChevronDown } from "./svg/icon-arrow-time-input-chevron-down.svg";

export type SvgProps = Omit<SVGProps<SVGElement>, "children" | "ref">;

interface Props extends HTMLAttributes<SVGElement> {
  size?: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SvgIcon = (Icon: any, props: Props) => {
  return createElement(Icon, props);
};

export const AddReportContentIcon = (props: SvgProps) =>
  SvgIcon(_AddReportContentIcon, props);
export const ChartTypeBarIcon = (props: SvgProps) =>
  SvgIcon(_ChartTypeBarIcon, props);
export const ChartTypeLineIcon = (props: SvgProps) =>
  SvgIcon(_ChartTypeLineIcon, props);
export const ChartTypeBarStackIcon = (props: SvgProps) =>
  SvgIcon(_ChartTypeBarStackIcon, props);
export const ChartTypeMultipleLineIcon = (props: SvgProps) =>
  SvgIcon(_ChartTypeMultipleLineIcon, props);
export const ChartTypeSingleLineIcon = (props: SvgProps) =>
  SvgIcon(_ChartTypeSingleLineIcon, props);
export const ChartTypePieDoughuntIcon = (props: SvgProps) =>
  SvgIcon(_ChartTypePieDoughuntIcon, props);
export const ChartTypePieIcon = (props: SvgProps) =>
  SvgIcon(_ChartTypePieIcon, props);
export const DeleteReportWidget = (props: SvgProps) =>
  SvgIcon(_DeleteReportWidget, props);
export const DragIcon = (props: SvgProps) => SvgIcon(_DragIcon, props);
export const IconAccident = (props: SvgProps) => SvgIcon(_IconAccident, props);
export const IconAdd = (props: SvgProps) => SvgIcon(_IconAdd, props);
export const IconAdmin = (props: SvgProps) => SvgIcon(_IconAdmin, props);
export const IconAfterAction = (props: SvgProps) =>
  SvgIcon(_IconAfterAction, props);
export const IconAlarm = (props: SvgProps) => SvgIcon(_IconAlarm, props);
export const IconAlarmLine = (props: SvgProps) =>
  SvgIcon(_IconAlarmLine, props);
export const IconArea = (props: SvgProps) => SvgIcon(_IconArea, props);
export const IconArrow = (props: SvgProps) => SvgIcon(_IconArrow, props);
export const IconArrowDown = (props: SvgProps) =>
  SvgIcon(_IconArrowDown, props);
export const IconArrowDownCalendar = (props: SvgProps) =>
  SvgIcon(_IconArrowDownCalendar, props);
export const IconArrowDownFill = (props: SvgProps) =>
  SvgIcon(_IconArrowDownFill, props);
export const IconArrowDownLine = (props: SvgProps) =>
  SvgIcon(_IconArrowDownLine, props);
export const IconArrowLeft = (props: SvgProps) =>
  SvgIcon(_IconArrowLeft, props);
export const IconArrowLeftLine = (props: SvgProps) =>
  SvgIcon(_IconArrowLeftLine, props);
export const IconArrowRight = (props: SvgProps) =>
  SvgIcon(_IconArrowRight, props);
export const IconArrowRightLine = (props: SvgProps) =>
  SvgIcon(_IconArrowRightLine, props);
export const IconArrowUp = (props: SvgProps) => SvgIcon(_IconArrowUp, props);
export const IconArrowUpFill = (props: SvgProps) =>
  SvgIcon(_IconArrowUpFill, props);
export const IconArrowUpLine = (props: SvgProps) =>
  SvgIcon(_IconArrowUpLine, props);
export const IconAuthenticate = (props: SvgProps) =>
  SvgIcon(_IconAuthenticate, props);
export const IconBagFill = (props: SvgProps) => SvgIcon(_IconBagFill, props);
export const IconBetaLogo = (props: SvgProps) => SvgIcon(_IconBetaLogo, props);
export const IconBicycle = (props: SvgProps) => SvgIcon(_IconBicycle, props);
export const IconBookmark = (props: SvgProps) => SvgIcon(_IconBookmark, props);
export const IconBox = (props: SvgProps) => SvgIcon(_IconBox, props);
export const IconBoxing = (props: SvgProps) => SvgIcon(_IconBoxing, props);
export const IconBus = (props: SvgProps) => SvgIcon(_IconBus, props);
export const IconCCTV = (props: SvgProps) => SvgIcon(_IconCCTV, props);
export const IconCalculator = (props: SvgProps) =>
  SvgIcon(_IconCalculator, props);
export const IconCalendar = (props: SvgProps) => SvgIcon(_IconCalendar, props);
export const IconCam = (props: SvgProps) => SvgIcon(_IconCam, props);
export const IconCamNone = (props: SvgProps) => SvgIcon(_IconCamNone, props);
export const IconCamOff = (props: SvgProps) => SvgIcon(_IconCamOff, props);
export const IconCamOn = (props: SvgProps) => SvgIcon(_IconCamOn, props);
export const IconCamcorderAvailable = (props: SvgProps) =>
  SvgIcon(_IconCamcorderAvailable, props);
export const IconCamcorderDisable = (props: SvgProps) =>
  SvgIcon(_IconCamcorderDisable, props);
export const IconCameraFill = (props: SvgProps) =>
  SvgIcon(_IconCameraFill, props);
export const IconCameraFillDisable = (props: SvgProps) =>
  SvgIcon(_IconCameraFillDisable, props);
export const IconCar = (props: SvgProps) => SvgIcon(_IconCar, props);
export const IconCarEvent = (props: SvgProps) => SvgIcon(_IconCarEvent, props);
export const IconCarFill = (props: SvgProps) => SvgIcon(_IconCarFill, props);
export const IconCard = (props: SvgProps) => SvgIcon(_IconCard, props);
export const IconCartFill = (props: SvgProps) => SvgIcon(_IconCartFill, props);
export const IconCaution = (props: SvgProps) => SvgIcon(_IconCaution, props);
export const IconCheckCircle = (props: SvgProps) =>
  SvgIcon(_IconCheckCircle, props);
export const IconCheckLine = (props: SvgProps) =>
  SvgIcon(_IconCheckLine, props);
export const IconCheckPolygon = (props: SvgProps) =>
  SvgIcon(_IconCheckPolygon, props);
export const IconCheckTick = (props: SvgProps) =>
  SvgIcon(_IconCheckTick, props);
export const IconCircle = (props: SvgProps) => SvgIcon(_IconCircle, props);
export const IconClose = (props: SvgProps) => SvgIcon(_IconClose, props);
export const IconCloseSmall = (props: SvgProps) =>
  SvgIcon(_IconCloseSmall, props);
export const IconCloud = (props: SvgProps) => SvgIcon(_IconCloud, props);
export const IconCloudActive = (props: SvgProps) =>
  SvgIcon(_IconCloudActive, props);
export const IconCloudDeactive = (props: SvgProps) =>
  SvgIcon(_IconCloudDeactive, props);
export const IconCloudFill = (props: SvgProps) =>
  SvgIcon(_IconCloudFill, props);
export const IconCloudPortal = (props: SvgProps) =>
  SvgIcon(_IconCloudPortal, props);
export const IconColorModeDark = (props: SvgProps) =>
  SvgIcon(_IconColorModeDark, props);
export const IconColorModeLight = (props: SvgProps) =>
  SvgIcon(_IconColorModeLight, props);
export const IconColorModeSystem = (props: SvgProps) =>
  SvgIcon(_IconColorModeSystem, props);
export const IconComputer = (props: SvgProps) => SvgIcon(_IconComputer, props);
export const IconConeFill = (props: SvgProps) => SvgIcon(_IconConeFill, props);
export const IconConfirmed = (props: SvgProps) =>
  SvgIcon(_IconConfirmed, props);
export const IconConnect = (props: SvgProps) => SvgIcon(_IconConnect, props);
export const IconCopy = (props: SvgProps) => SvgIcon(_IconCopy, props);
export const IconCrowdSafetyStatistics = (props: SvgProps) =>
  SvgIcon(_IconCrowdSafetyStatistics, props);
export const IconCurrentLocationDot = (props: SvgProps) =>
  SvgIcon(_IconCurrentLocationDot, props);
export const IconDanger = (props: SvgProps) => SvgIcon(_IconDanger, props);
export const IconDashboardSequence = (props: SvgProps) =>
  SvgIcon(_IconDashboardSequence, props);
export const IconDataCircle = (props: SvgProps) =>
  SvgIcon(_IconDataCircle, props);
export const IconDataFill = (props: SvgProps) => SvgIcon(_IconDataFill, props);
export const IconDataLine = (props: SvgProps) => SvgIcon(_IconDataLine, props);
export const IconDatatypeCreate = (props: SvgProps) =>
  SvgIcon(_IconDatatypeCreate, props);
export const IconDelete = (props: SvgProps) => SvgIcon(_IconDelete, props);
export const IconDeleteReport = (props: SvgProps) =>
  SvgIcon(_IconDeleteReport, props);
export const IconDetecting = (props: SvgProps) =>
  SvgIcon(_IconDetecting, props);
export const IconDetectingLight = (props: SvgProps) =>
  SvgIcon(_IconDetectingLight, props);
export const IconDevice = (props: SvgProps) => SvgIcon(_IconDevice, props);
export const IconDomeCamera = (props: SvgProps) =>
  SvgIcon(_IconDomeCamera, props);
export const IconDone = (props: SvgProps) => SvgIcon(_IconDone, props);
export const IconDown = (props: SvgProps) => SvgIcon(_IconDown, props);
export const IconDownFill = (props: SvgProps) => SvgIcon(_IconDownFill, props);
export const IconEdit = (props: SvgProps) => SvgIcon(_IconEdit, props);
export const IconEditSmall = (props: SvgProps) =>
  SvgIcon(_IconEditSmall, props);
export const IconEmail = (props: SvgProps) => SvgIcon(_IconEmail, props);
export const IconEmphasis = (props: SvgProps) => SvgIcon(_IconEmphasis, props);
export const IconEvent = (props: SvgProps) => SvgIcon(_IconEvent, props);
export const IconEventActive = (props: SvgProps) =>
  SvgIcon(_IconEventActive, props);
export const IconEventTypeAll = (props: SvgProps) =>
  SvgIcon(_IconEventTypeAll, props);
export const IconExpansionWidgetStyle = (props: SvgProps) =>
  SvgIcon(_IconExpansionWidgetStyle, props);
export const IconExport = (props: SvgProps) => SvgIcon(_IconExport, props);
export const IconEyeOffLine = (props: SvgProps) =>
  SvgIcon(_IconEyeOffLine, props);
export const IconEyeOnLine = (props: SvgProps) =>
  SvgIcon(_IconEyeOnLine, props);
export const IconFactory = (props: SvgProps) => SvgIcon(_IconFactory, props);
export const IconFileAddFill = (props: SvgProps) =>
  SvgIcon(_IconFileAddFill, props);
export const IconFilter = (props: SvgProps) => SvgIcon(_IconFilter, props);
export const IconFilterAllEvent = (props: SvgProps) =>
  SvgIcon(_IconFilterAllEvent, props);
export const IconFix = (props: SvgProps) => SvgIcon(_IconFix, props);
export const IconFloatingAdd = (props: SvgProps) =>
  SvgIcon(_IconFloatingAdd, props);
export const IconFloatingClose = (props: SvgProps) =>
  SvgIcon(_IconFloatingClose, props);
export const IconFolder = (props: SvgProps) => SvgIcon(_IconFolder, props);
export const IconFolderCharacterFill = (props: SvgProps) =>
  SvgIcon(_IconFolderCharacterFill, props);
export const IconFolderOpenLine = (props: SvgProps) =>
  SvgIcon(_IconFolderOpenLine, props);
export const IconForklift = (props: SvgProps) => SvgIcon(_IconForklift, props);
export const IconGPS = (props: SvgProps) => SvgIcon(_IconGPS, props);
export const IconGreenDot = (props: SvgProps) => SvgIcon(_IconGreenDot, props);
export const IconGrid = (props: SvgProps) => SvgIcon(_IconGrid, props);
export const IconGroup = (props: SvgProps) => SvgIcon(_IconGroup, props);
export const IconGroupAdd = (props: SvgProps) => SvgIcon(_IconGroupAdd, props);
export const IconHeatmap = (props: SvgProps) => SvgIcon(_IconHeatmap, props);
export const IconHelmet = (props: SvgProps) => SvgIcon(_IconHelmet, props);
export const IconHelp = (props: SvgProps) => SvgIcon(_IconHelp, props);
export const IconImageUpload = (props: SvgProps) =>
  SvgIcon(_IconImageUpload, props);
export const IconImportDevice = (props: SvgProps) =>
  SvgIcon(_IconImportDevice, props);
export const IconInbDashboardFill = (props: SvgProps) =>
  SvgIcon(_IconInbDashboardFill, props);
export const IconInbDashboardLine = (props: SvgProps) =>
  SvgIcon(_IconInbDashboardLine, props);
export const IconInbEventFill = (props: SvgProps) =>
  SvgIcon(_IconInbEventFill, props);
export const IconInbEventLine = (props: SvgProps) =>
  SvgIcon(_IconInbEventLine, props);
export const IconInbReportFill = (props: SvgProps) =>
  SvgIcon(_IconInbReportFill, props);
export const IconInbReportLine = (props: SvgProps) =>
  SvgIcon(_IconInbReportLine, props);
export const IconInbSettingFill = (props: SvgProps) =>
  SvgIcon(_IconInbSettingFill, props);
export const IconInbSettingLine = (props: SvgProps) =>
  SvgIcon(_IconInbSettingLine, props);
export const IconIndeterminate = (props: SvgProps) =>
  SvgIcon(_IconIndeterminate, props);
export const IconInfo = (props: SvgProps) => SvgIcon(_IconInfo, props);
export const IconInfoBig = (props: SvgProps) => SvgIcon(_IconInfoBig, props);
export const IconKey = (props: SvgProps) => SvgIcon(_IconKey, props);
export const IconLaptop = (props: SvgProps) => SvgIcon(_IconLaptop, props);
export const IconLayer = (props: SvgProps) => SvgIcon(_IconLayer, props);
export const IconLeftCounts = (props: SvgProps) =>
  SvgIcon(_IconLeftCounts, props);
export const IconLens = (props: SvgProps) => SvgIcon(_IconLens, props);
export const IconLightning = (props: SvgProps) =>
  SvgIcon(_IconLightning, props);
export const IconLinkDisconnected = (props: SvgProps) =>
  SvgIcon(_IconLinkDisconnected, props);
export const IconLinked = (props: SvgProps) => SvgIcon(_IconLinked, props);
export const IconList = (props: SvgProps) => SvgIcon(_IconList, props);
export const IconLive = (props: SvgProps) => SvgIcon(_IconLive, props);
export const IconLoader = (props: SvgProps) => SvgIcon(_IconLoader, props);
export const IconLockFill = (props: SvgProps) => SvgIcon(_IconLockFill, props);
export const IconLogoBig = (props: SvgProps) => SvgIcon(_IconLogoBig, props);
export const IconLogoLogin = (props: SvgProps) =>
  SvgIcon(_IconLogoLogin, props);
export const IconLogout = (props: SvgProps) => SvgIcon(_IconLogout, props);
export const IconMainWidgetStyle = (props: SvgProps) =>
  SvgIcon(_IconMainWidgetStyle, props);
export const IconMapAdd = (props: SvgProps) => SvgIcon(_IconMapAdd, props);
export const IconMapEvent = (props: SvgProps) => SvgIcon(_IconMapEvent, props);
export const IconMapFill = (props: SvgProps) => SvgIcon(_IconMapFill, props);
export const IconMapNew = (props: SvgProps) => SvgIcon(_IconMapNew, props);
export const IconMapPinFill = (props: SvgProps) =>
  SvgIcon(_IconMapPinFill, props);
export const IconMaster = (props: SvgProps) => SvgIcon(_IconMaster, props);
export const IconMesManagement = (props: SvgProps) =>
  SvgIcon(_IconMesManagement, props);
export const IconMesManagementDone = (props: SvgProps) =>
  SvgIcon(_IconMesManagementDone, props);
export const IconMesManagementError = (props: SvgProps) =>
  SvgIcon(_IconMesManagementError, props);
export const IconMesManagementNoAction = (props: SvgProps) =>
  SvgIcon(_IconMesManagementNoAction, props);
export const IconMessageLine = (props: SvgProps) =>
  SvgIcon(_IconMessageLine, props);
export const IconMinus = (props: SvgProps) => SvgIcon(_IconMinus, props);
export const IconMoney = (props: SvgProps) => SvgIcon(_IconMoney, props);
export const IconMonitor = (props: SvgProps) => SvgIcon(_IconMonitor, props);
export const IconMore = (props: SvgProps) => SvgIcon(_IconMore, props);
export const IconMoreSmall = (props: SvgProps) =>
  SvgIcon(_IconMoreSmall, props);
export const IconMotorcycle = (props: SvgProps) =>
  SvgIcon(_IconMotorcycle, props);
export const IconNew = (props: SvgProps) => SvgIcon(_IconNew, props);
export const IconNoAction = (props: SvgProps) => SvgIcon(_IconNoAction, props);
export const IconNoData = (props: SvgProps) => SvgIcon(_IconNoData, props);
export const IconNoDevice = (props: SvgProps) => SvgIcon(_IconNoDevice, props);
export const IconNoPlayer = (props: SvgProps) => SvgIcon(_IconNoPlayer, props);
export const IconNoResult = (props: SvgProps) => SvgIcon(_IconNoResult, props);
export const IconNoUse = (props: SvgProps) => SvgIcon(_IconNoUse, props);
export const IconNonCheck = (props: SvgProps) => SvgIcon(_IconNonCheck, props);
export const IconNoneFill = (props: SvgProps) => SvgIcon(_IconNoneFill, props);
export const IconNotUse = (props: SvgProps) => SvgIcon(_IconNotUse, props);
export const IconOiling = (props: SvgProps) => SvgIcon(_IconOiling, props);
export const IconOrganization = (props: SvgProps) =>
  SvgIcon(_IconOrganization, props);
export const IconParking = (props: SvgProps) => SvgIcon(_IconParking, props);
export const IconPeople = (props: SvgProps) => SvgIcon(_IconPeople, props);
export const IconPeopleCount = (props: SvgProps) =>
  SvgIcon(_IconPeopleCount, props);
export const IconPeopleCounting = (props: SvgProps) =>
  SvgIcon(_IconPeopleCounting, props);
export const IconPersonWalking = (props: SvgProps) =>
  SvgIcon(_IconPersonWalking, props);
export const IconPersonWalkingCircle = (props: SvgProps) =>
  SvgIcon(_IconPersonWalkingCircle, props);
export const IconPinCharacterFill = (props: SvgProps) =>
  SvgIcon(_IconPinCharacterFill, props);
export const IconPinFill = (props: SvgProps) => SvgIcon(_IconPinFill, props);
export const IconPinPushLine = (props: SvgProps) =>
  SvgIcon(_IconPinPushLine, props);
export const IconPlay = (props: SvgProps) => SvgIcon(_IconPlay, props);
export const IconPlayBack = (props: SvgProps) => SvgIcon(_IconPlayBack, props);
export const IconPlayButton = (props: SvgProps) =>
  SvgIcon(_IconPlayButton, props);
export const IconPlayLine = (props: SvgProps) => SvgIcon(_IconPlayLine, props);
export const IconPlus = (props: SvgProps) => SvgIcon(_IconPlus, props);
export const IconPlusClaim = (props: SvgProps) =>
  SvgIcon(_IconPlusClaim, props);
export const IconPos = (props: SvgProps) => SvgIcon(_IconPos, props);
export const IconProfileFill = (props: SvgProps) =>
  SvgIcon(_IconProfileFill, props);
export const IconProtect = (props: SvgProps) => SvgIcon(_IconProtect, props);
export const IconQuestionMark = (props: SvgProps) =>
  SvgIcon(_IconQuestionMark, props);
export const IconQueueManagement = (props: SvgProps) =>
  SvgIcon(_IconQueueManagement, props);
export const IconReceipt = (props: SvgProps) => SvgIcon(_IconReceipt, props);
export const IconReceiptAvailable = (props: SvgProps) =>
  SvgIcon(_IconReceiptAvailable, props);
export const IconReceiptDisable = (props: SvgProps) =>
  SvgIcon(_IconReceiptDisable, props);
export const IconRedDot = (props: SvgProps) => SvgIcon(_IconRedDot, props);
export const IconRefresh = (props: SvgProps) => SvgIcon(_IconRefresh, props);
export const IconRefreshLine = (props: SvgProps) =>
  SvgIcon(_IconRefreshLine, props);
export const IconReset = (props: SvgProps) => SvgIcon(_IconReset, props);
export const IconReturnArrow = (props: SvgProps) =>
  SvgIcon(_IconReturnArrow, props);
export const IconRightArrow = (props: SvgProps) =>
  SvgIcon(_IconRightArrow, props);
export const IconRightCounts = (props: SvgProps) =>
  SvgIcon(_IconRightCounts, props);
export const IconRightTopArrow = (props: SvgProps) =>
  SvgIcon(_IconRightTopArrow, props);
export const IconRoot = (props: SvgProps) => SvgIcon(_IconRoot, props);
export const IconRootFill = (props: SvgProps) => SvgIcon(_IconRootFill, props);
export const IconRule = (props: SvgProps) => SvgIcon(_IconRule, props);
export const IconRuleForklift = (props: SvgProps) =>
  SvgIcon(_IconRuleForklift, props);
export const IconRuleQueueManagement = (props: SvgProps) =>
  SvgIcon(_IconRuleQueueManagement, props);
export const IconRuleSmall = (props: SvgProps) =>
  SvgIcon(_IconRuleSmall, props);
export const IconSales = (props: SvgProps) => SvgIcon(_IconSales, props);
export const IconSearch = (props: SvgProps) => SvgIcon(_IconSearch, props);
export const IconSelectRule = (props: SvgProps) =>
  SvgIcon(_IconSelectRule, props);
export const IconSequence = (props: SvgProps) => SvgIcon(_IconSequence, props);
export const IconSetting = (props: SvgProps) => SvgIcon(_IconSetting, props);
export const IconSetting84 = (props: SvgProps) =>
  SvgIcon(_IconSetting84, props);
export const IconShareLine = (props: SvgProps) =>
  SvgIcon(_IconShareLine, props);
export const IconShopFill = (props: SvgProps) => SvgIcon(_IconShopFill, props);
export const IconShoppingCart = (props: SvgProps) =>
  SvgIcon(_IconShoppingCart, props);
export const IconShoppingCartCircle = (props: SvgProps) =>
  SvgIcon(_IconShoppingCartCircle, props);
export const IconShoppingCartLine = (props: SvgProps) =>
  SvgIcon(_IconShoppingCartLine, props);
export const IconShow = (props: SvgProps) => SvgIcon(_IconShow, props);
export const IconSightMind = (props: SvgProps) =>
  SvgIcon(_IconSightMind, props);
export const IconSlipAndFall = (props: SvgProps) =>
  SvgIcon(_IconSlipAndFall, props);
export const IconSlipAndFallMulti = (props: SvgProps) =>
  SvgIcon(_IconSlipAndFallMulti, props);
export const IconSlipAndFallSingle = (props: SvgProps) =>
  SvgIcon(_IconSlipAndFallSingle, props);
export const IconSlipFall = (props: SvgProps) => SvgIcon(_IconSlipFall, props);
export const IconSort = (props: SvgProps) => SvgIcon(_IconSort, props);
export const IconSpeechBubble = (props: SvgProps) =>
  SvgIcon(_IconSpeechBubble, props);
export const IconSpeeding = (props: SvgProps) => SvgIcon(_IconSpeeding, props);
export const IconStopLine = (props: SvgProps) => SvgIcon(_IconStopLine, props);
export const IconSubtract = (props: SvgProps) => SvgIcon(_IconSubtract, props);
export const IconSubtractLine = (props: SvgProps) =>
  SvgIcon(_IconSubtractLine, props);
export const IconTempFill = (props: SvgProps) => SvgIcon(_IconTempFill, props);
export const IconTemperature = (props: SvgProps) =>
  SvgIcon(_IconTemperature, props);
export const IconTemperatureEvent = (props: SvgProps) =>
  SvgIcon(_IconTemperatureEvent, props);
export const IconThumbnailPlay = (props: SvgProps) =>
  SvgIcon(_IconThumbnailPlay, props);
export const IconTime = (props: SvgProps) => SvgIcon(_IconTime, props);
export const IconToiletMan = (props: SvgProps) =>
  SvgIcon(_IconToiletMan, props);
export const IconToiletWoman = (props: SvgProps) =>
  SvgIcon(_IconToiletWoman, props);
export const IconTool = (props: SvgProps) => SvgIcon(_IconTool, props);
export const IconTopRightArrow = (props: SvgProps) =>
  SvgIcon(_IconTopRightArrow, props);
export const IconTrafficJamEvent = (props: SvgProps) =>
  SvgIcon(_IconTrafficJamEvent, props);
export const IconTrafficJamEventMulti = (props: SvgProps) =>
  SvgIcon(_IconTrafficJamEventMulti, props);
export const IconTrash = (props: SvgProps) => SvgIcon(_IconTrash, props);
export const IconTreeViewCheck = (props: SvgProps) =>
  SvgIcon(_IconTreeViewCheck, props);
export const IconTreeViewHalfCheck = (props: SvgProps) =>
  SvgIcon(_IconTreeViewHalfCheck, props);
export const IconTreeViewUnCheck = (props: SvgProps) =>
  SvgIcon(_IconTreeViewUnCheck, props);
export const IconTruck = (props: SvgProps) => SvgIcon(_IconTruck, props);
export const IconTruckFill = (props: SvgProps) =>
  SvgIcon(_IconTruckFill, props);
export const IconUnlockFill = (props: SvgProps) =>
  SvgIcon(_IconUnlockFill, props);
export const IconUp = (props: SvgProps) => SvgIcon(_IconUp, props);
export const IconUpCounts = (props: SvgProps) => SvgIcon(_IconUpCounts, props);
export const IconUpFill = (props: SvgProps) => SvgIcon(_IconUpFill, props);
export const IconUpload = (props: SvgProps) => SvgIcon(_IconUpload, props);
export const IconUserAdd = (props: SvgProps) => SvgIcon(_IconUserAdd, props);
export const IconUserLogout = (props: SvgProps) =>
  SvgIcon(_IconUserLogout, props);
export const IconUserProfile = (props: SvgProps) =>
  SvgIcon(_IconUserProfile, props);
export const IconVehicle = (props: SvgProps) => SvgIcon(_IconVehicle, props);
export const IconVehicleCircle = (props: SvgProps) =>
  SvgIcon(_IconVehicleCircle, props);
export const IconVehicleQueue = (props: SvgProps) =>
  SvgIcon(_IconVehicleQueue, props);
export const IconVest = (props: SvgProps) => SvgIcon(_IconVest, props);
export const IconVideoCamera = (props: SvgProps) =>
  SvgIcon(_IconVideoCamera, props);
export const IconVideoCameraAvailable = (props: SvgProps) =>
  SvgIcon(_IconVideoCameraAvailable, props);
export const IconVideoCameraDisable = (props: SvgProps) =>
  SvgIcon(_IconVideoCameraDisable, props);
export const IconVideoEnterFullscreen = (props: SvgProps) =>
  SvgIcon(_IconVideoEnterFullscreen, props);
export const IconVideoExitFullscreen = (props: SvgProps) =>
  SvgIcon(_IconVideoExitFullscreen, props);
export const IconVideoPause = (props: SvgProps) =>
  SvgIcon(_IconVideoPause, props);
export const IconVideoPlay = (props: SvgProps) =>
  SvgIcon(_IconVideoPlay, props);
export const IconVideoSkip = (props: SvgProps) =>
  SvgIcon(_IconVideoSkip, props);
export const IconVideoStop = (props: SvgProps) =>
  SvgIcon(_IconVideoStop, props);
export const IconWaveSync = (props: SvgProps) => SvgIcon(_IconWaveSync, props);
export const IconWaveSyncDeactive = (props: SvgProps) =>
  SvgIcon(_IconWaveSyncDeactive, props);
export const IconWaveSyncLogout = (props: SvgProps) =>
  SvgIcon(_IconWaveSyncLogout, props);
export const IconWidgetForklift = (props: SvgProps) =>
  SvgIcon(_IconWidgetForklift, props);
export const IconWifi = (props: SvgProps) => SvgIcon(_IconWifi, props);
export const IconWorker = (props: SvgProps) => SvgIcon(_IconWorker, props);
export const IconWorkerAll = (props: SvgProps) =>
  SvgIcon(_IconWorkerAll, props);
export const IconWorkerDay = (props: SvgProps) =>
  SvgIcon(_IconWorkerDay, props);
export const IconWorkshop = (props: SvgProps) => SvgIcon(_IconWorkshop, props);
export const IconYellowDot = (props: SvgProps) =>
  SvgIcon(_IconYellowDot, props);
export const IconYoutube = (props: SvgProps) => SvgIcon(_IconYoutube, props);
export const IconZoomIn = (props: SvgProps) => SvgIcon(_IconZoomIn, props);
export const IconZoomOut = (props: SvgProps) => SvgIcon(_IconZoomOut, props);
export const MixChartLineAndBar = (props: SvgProps) =>
  SvgIcon(_MixChartLineAndBar, props);
export const MixedChartLineAndArea = (props: SvgProps) =>
  SvgIcon(_MixedChartLineAndArea, props);
export const ReportChartContentIcon = (props: SvgProps) =>
  SvgIcon(_ReportChartContentIcon, props);
export const ReportDashboardMore = (props: SvgProps) =>
  SvgIcon(_ReportDashboardMore, props);
export const ReportTextContentIcon = (props: SvgProps) =>
  SvgIcon(_ReportTextContentIcon, props);
export const ReportWidgetEdit = (props: SvgProps) =>
  SvgIcon(_ReportWidgetEdit, props);
export const SelectWidgetIcon = (props: SvgProps) =>
  SvgIcon(_SelectWidgetIcon, props);
export const SendEmailVectorIcon = (props: SvgProps) =>
  SvgIcon(_SendEmailVectorIcon, props);
export const HandleBar = (props: SvgProps) => SvgIcon(_HandleBar, props);
export const IconImageNotAvailable = (props: SvgProps) =>
  SvgIcon(_IconImageNotAvailable, props);
export const ReportHeatmap = (props: SvgProps) =>
  SvgIcon(_ReportHeatmap, props);
export const IconTreeArrow = (props: SvgProps) =>
  SvgIcon(_IconTreeArrow, props);
export const IconTreeDashboard = (props: SvgProps) =>
  SvgIcon(_IconTreeDashboard, props);
export const IconTreeFolder = (props: SvgProps) =>
  SvgIcon(_IconTreeFolder, props);

export const IconTreeFolderOpen = (props: SvgProps) =>
  SvgIcon(_IconTreeFolderOpen, props);
export const IconMoreMenu = (props: SvgProps) => SvgIcon(_IconMoreMenu, props);
export const IconTreeFold = (props: SvgProps) => SvgIcon(_IconTreeFold, props);
export const IconFilterOutline = (props: SvgProps) =>
  SvgIcon(_IconFilterOutline, props);
export const SightMindLogo = (props: SvgProps) =>
  SvgIcon(_SightMindLogo, props);
export const SightMindIconLogo = (props: SvgProps) =>
  SvgIcon(_SightMindIconLogo, props);

export const IconRadar = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: conic-gradient(#000000, #00b960);
  animation: scan 2s infinite linear;
  @keyframes scan {
    to {
      transform: rotate(1turn);
    }
  }
`;

export const HorizontalBar = (props: SvgProps) =>
  SvgIcon(_HorizontalBar, props);

export const TopCount1 = (props: SvgProps) => SvgIcon(_TopCount1, props);

export const TopCount2 = (props: SvgProps) => SvgIcon(_TopCount2, props);

export const TopCount3 = (props: SvgProps) => SvgIcon(_TopCount3, props);

export const InfoIcon = (props: SvgProps) => SvgIcon(_InfoIcon, props);

export const IconProfileLine = (props: SvgProps) =>
  SvgIcon(_IconProfileLine, props);

export const ReportFileIcon = (props: SvgProps) => SvgIcon(_ReportFile, props);

export const IconShare = (props: SvgProps) => SvgIcon(_IconShare, props);
export const IconArrowSolidChevronUp = (props: SvgProps) =>
  SvgIcon(_IconArrowSolidChevronUp, props);
export const IconArrowSolidChevronDown = (props: SvgProps) =>
  SvgIcon(_IconArrowSolidChevronDown, props);
export const IconArrowChevronDown = (props: SvgProps) =>
  SvgIcon(_IconArrowChevronDown, props);
export const IconArrowChevronUp = (props: SvgProps) =>
  SvgIcon(_IconArrowChevronUp, props);
export const IconArrowTimeInputChevronUp = (props: SvgProps) =>
  SvgIcon(_IconArrowTimeInputChevronUp, props);
export const IconArrowTimeInputChevronDown = (props: SvgProps) =>
  SvgIcon(_IconArrowTimeInputChevronDown, props);
