import {
  CalendarModeRangeType,
  ColorMode,
  Lang,
  LocationInfo,
  PermissionsInfo
} from "@custom-types/Types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import momentTimeZone from "moment-timezone";
import {
  CALENDAR_MODE_RANGE_TYPE,
  FORMAT,
  LANG,
  THEME_TYPE,
  VIEWER_PERMISSIONS
} from "@resources/Constants";

interface SystemState {
  colorMode: ColorMode;
  lang: Lang;
  dateFormat: string;
  datetimeFormat: string;
  dateTime: {
    mode: CalendarModeRangeType;
    start: string;
    end: string;
    comparisonStart: string;
    comparisonEnd: string;
  };
  userLocation: LocationInfo;
  userPermissions: PermissionsInfo<boolean>;
  userId: string;
}

const currentTimeZone = momentTimeZone.tz.guess();

const startDateTime = momentTimeZone
  .tz(currentTimeZone)
  .startOf("day")
  .toISOString(true);
const endDateTime = momentTimeZone.tz(currentTimeZone).toISOString(true);
const yesterday = momentTimeZone
  .tz(currentTimeZone)
  .subtract(1, "day")
  .toISOString(true);

const initialState: SystemState = {
  colorMode: THEME_TYPE.LIGHT,
  lang: LANG.EN,
  dateFormat: FORMAT.DATE2,
  datetimeFormat: FORMAT.DATE_TIME_12H,
  dateTime: {
    mode: CALENDAR_MODE_RANGE_TYPE.TODAY,
    start: startDateTime,
    end: endDateTime,
    comparisonStart: yesterday,
    comparisonEnd: yesterday
  },
  userLocation: {
    lat: 37,
    lng: -98
  },
  userPermissions: VIEWER_PERMISSIONS,
  userId: ""
};

const systemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    updateDateTime: (state, action: PayloadAction<SystemState["dateTime"]>) => {
      return {
        ...state,
        dateTime: action.payload
      };
    },
    switchColorMode: (state, action: PayloadAction<ColorMode>) => {
      return {
        ...state,
        colorMode: action.payload
      };
    },
    setUserLocation: (state, action: PayloadAction<LocationInfo>) => {
      return {
        ...state,
        userLocation: action.payload
      };
    },
    setUserPermissions: (
      state,
      action: PayloadAction<PermissionsInfo<boolean>>
    ) => {
      return {
        ...state,
        userPermissions: action.payload
      };
    },
    setUserId: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        userId: action.payload
      };
    }
  }
});

export const {
  updateDateTime,
  switchColorMode,
  setUserLocation,
  setUserPermissions,
  setUserId
} = systemSlice.actions;

export default systemSlice.reducer;
